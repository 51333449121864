export const removeTrailingSlash = (value: string) => {
  return value.charAt(value.length - 1) === '/' ? value.substring(0, value.length - 1) : value
}

export const secondsToDurationString = (seconds: number) => {
  if (seconds < 60) {
    return Math.floor(seconds) + 's'
  }
  return Math.floor(seconds / 60) + 'min'
}

export const ellipsize = (value: string, length?: number) => {
  if (length && length > 3 && value.length > length - 3) {
    return value.substring(0, length - 3) + '...'
  }
  return value
}

export const capitalize = (value: string) => {
  if (value.length === 0) {
    return value
  }
  const x = value.charAt(0).toUpperCase()
  return `${x}${value.substring(1)}`
}
