import React, { MutableRefObject, useRef, useState } from 'react';
import { classNames } from 'common/helpers/styling';
import { FileImage, FileVideo, CloudCheck, CloudArrowUp, VideoCamera } from 'phosphor-react';
import { stringHelpers } from 'common/helpers';
import { useErrorModal } from 'common/hooks/useConfirmationPrompt';
import styles from './MediaEditor.module.scss';

const maxImageFileSize = 10;
const maxVideoFileSize = 500;
const videoExtensions = ['mp4']
const imageExtensions = ['png', 'jpg', 'jpeg']
const getFileExtensionFromName = (name: string) => name.split('.').pop()?.toLowerCase()

interface Props {
  onChange: (file: File) => void
  type: 'image' | 'video'
  displayUrl?: string
}

const MediaEditor = ({
  onChange,
  type,
  displayUrl,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const [ displayUrlOverride, setDisplayUrlOverride ] = useState<string | null>(null);

  const errorModal = useErrorModal();

  const validateMedia = (file: File, itemName: string, maxSize: number, extensions: string[]) => {
    const size = Math.floor(file.size / 1024 / 1024);
    if (size > maxSize) {
      errorModal(`A kiválasztott ${
        itemName
      } túl nagy (${
        size
      } Mb)! A megengedett maximális méret ${
        maxSize
      } Mb.`);
      return false;
    }
    const extension = getFileExtensionFromName(file.name) || '';
    if (!extensions.includes(extension)) {
      errorModal(`A kiválasztott ${
        itemName
      } formátuma nem megfelelő. Engedélyezett formátum(ok): [${
        extensions.join(', ')
      }].`)
      return false;
    }
    return true;
  }

  const onImageSelected = async (files: FileList | null) => {
    if(files && files.length > 0) {
      if(!validateMedia(files[0], 'kép', maxImageFileSize, imageExtensions)) {
        return;
      }
      onChange(files[0]);
      setDisplayUrlOverride(URL.createObjectURL(files[0]));
    }
  }

  const onVideoSelected = async (files: FileList | null) => {
    if(files && files.length > 0) {
      if(!validateMedia(files[0], 'videó', maxVideoFileSize, videoExtensions)) {
        return;
      }
      onChange(files[0]);
      const override = `${
        stringHelpers.ellipsize(files[0].name, 48)
      } (${
        Math.floor(files[0].size / 1024 / 1024)
      } Mb)`
      setDisplayUrlOverride(override);
    }
  }

  const getImageUploadInstructions = () => {
    if (displayUrl && !displayUrlOverride) {
      return (
        <div className={classNames(styles.instructions, styles.overlay)}>
          <CloudCheck className={styles.icon} />
          <div className={styles.title}>
            <h3>Kép feltöltve</h3>
            <h4>Kattintson ide egy másik kép kiválasztásához</h4>
          </div>
        </div>
      )
    }
    if (displayUrlOverride) {
      return (
        <div className={classNames(styles.instructions, styles.overlay)}>
          <CloudArrowUp className={styles.icon} />
          <div className={styles.title}>
            <h3>Kép kiválasztva</h3>
            <h4>A kép a szerkesztés befejeztével kerül feltöltésre</h4>
          </div>
        </div>
      )
    }
    return (
      <div className={styles.instructions}>
        <FileImage className={styles.icon} />
        <div className={styles.title}>
          <h3>Kép kiválasztása</h3>
          <h4>Kattintson ide az előnézeti kép kiválasztásához</h4>
        </div>
      </div>
    )
  }

  if (type === 'image') {
    return (
      <div
        className={classNames(styles.container, styles.image)}
        onClick={() => inputRef.current.click()}
      >
        { (!!displayUrl || !!displayUrlOverride) && <img draggable='false' src={displayUrlOverride || displayUrl} title='Előnézeti kép' aria-label='thumbnail' /> }
        {getImageUploadInstructions()}
        <input
          type='file'
          accept='image/jpeg, image/png'
          value={''}
          ref={inputRef}
          onChange={(event) => onImageSelected(event.target.files)}
        />
      </div>
    )
  }

  const getVideoUploadInstructions = () => {
    if (displayUrl && !displayUrlOverride) {
      return (
        <div className={styles.instructions}>
          <CloudCheck className={classNames(
            styles.icon,
            styles.green,
          )} />
          <div className={styles.title}>
            <h3>Videó feltöltve</h3>
            <h4>Kattintson ide egy másik videó kiválasztásához</h4>
          </div>
        </div>
      )
    }
    if (displayUrlOverride) {
      return (
        <div className={styles.instructions}>
          <CloudArrowUp className={classNames(styles.icon)} />
          <div className={styles.title}>
            <h3>Videó kiválasztva</h3>
            <h4>
              <strong>
                <VideoCamera weight='fill' />
                { displayUrlOverride }
              </strong>
            </h4>
            <h4>A videó a szerkesztés befejeztével kerül feltöltésre</h4>
          </div>
        </div>
      ) 
    }
    return (
      <div className={styles.instructions}>
        <FileVideo className={classNames(styles.icon)} />
        <div className={styles.title}>
          <h3>Videó kiválasztása</h3>
          <h4>Kattintson ide a videó kiválasztásához</h4>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.container, styles.video)}
      onClick={() => inputRef.current.click()}
    >
      {getVideoUploadInstructions()}
      <input
        type='file'
        accept='video/mp4'
        ref={inputRef}
        onChange={(event) => onVideoSelected(event.target.files)}
      />
    </div>
  )
}

export default MediaEditor;
