import { Dropdown } from "components"
import { DropdownOptionType } from "components/Dropdown/Dropdown"
import React, { useState } from "react"
import { UserRolesEnum } from "services/content.service/content.types"
import { ContentChangableBaseData } from "../EditContentBaseData"
import styles from "./DisplayCategoryEditor.module.scss"
import { SectionSubtitle } from "../../SectionTitle"

const options: DropdownOptionType[] = [
  {
    value: UserRolesEnum.Public,
    label: "Publikus",
  },
  {
    value: UserRolesEnum.Generic,
    label: "Regisztált felhasználók",
  },
  {
    value: UserRolesEnum.Teacher,
    label: "Pedagógus felhasználók",
  },
  {
    value: UserRolesEnum.User,
    label: "Meghívott felhasználók",
  },
  {
    value: UserRolesEnum.Admin,
    label: "Adminisztrátorok",
  },
]

interface Props {
  initialValue: number
  updateForSave: (data: ContentChangableBaseData) => void
}

const DisplayCategoryEditor = ({ initialValue, updateForSave }: Props) => {
  const [updatedContentCategory, setUpdatedContentCategory] = useState(initialValue)

  const onChange = (val: number) => {
    setUpdatedContentCategory(val)
    updateForSave({ userRoleId: val })
  }

  return (
    <span>
      <SectionSubtitle>Ki férhet hozzá a tananyaghoz</SectionSubtitle>
      <Dropdown
        options={options}
        value={updatedContentCategory}
        onChange={(val) => val && typeof val === "number" && onChange(val)}
        className={styles.dropdown}
      />
    </span>
  )
}

export default DisplayCategoryEditor
