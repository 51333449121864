import React from 'react'
import { X as CloseIcon } from 'phosphor-react'
import { classNames } from 'common/helpers/styling'
import styles from './Card.module.scss'

interface Props {
  children?: React.ReactNode
  className?: string
  header?: {
    title: string
    close?: () => void
  }
  style?: React.CSSProperties
  onClick?: () => void
}

export const Card = ({ children, className, header, style, onClick }: Props) => {
  return (
    <div className={classNames(styles.card, className)} style={style} onClick={onClick}>
      {header && (
        <div className={styles.header}>
          <h2>{header.title}</h2>
          {header.close && (
            <CloseIcon className={styles.icon} weight="bold" size={25} onClick={header.close} />
          )}
        </div>
      )}
      {children}
    </div>
  )
}
