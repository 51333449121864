import React from 'react'
import {
  StatisticsResponse,
  UserGroups,
  userGroupsTuple,
} from 'services/statistics.service/statistics.types'
import OpenableTable from 'pages/Admin/AdminStatisticsPage/AdminsTable/OpenableTable/OpenableTable'

interface ViewsByUserTypeTableProps {
  data: StatisticsResponse
}

const tableHeader = [
  'Csoport',
  'Megtekintések száma',
  'Sikeres kvíz kitöltés',
  'Sikertelen kvíz kitöltés',
]
const userGroupLabels: Record<UserGroups, string> = {
  female: 'Nő',
  between_25_50: '25-50 éves',
  male: 'Férfi',
  not_registered: 'Regisztráció nélkül',
  over_50: '50 évesnél több',
  registered: 'Regisztrált',
  teacher: 'Tanári regisztráció',
  under_25: '25 év alatt',
}

const convertData = (data: StatisticsResponse) => {
  const results: (number | string)[] = [...tableHeader]
  userGroupsTuple.reverse().forEach((groupName) => {
    results.push(userGroupLabels[groupName])
    results.push(data.results_by_users[groupName] || 0)
    results.push(data.quiz_general.successful[groupName] || 0)
    results.push(data.quiz_general.unsuccessful[groupName] || 0)
  })
  return results
}

const ViewsByUserTypeTable: React.FC<ViewsByUserTypeTableProps> = ({ data }) => {
  return (
    <OpenableTable
      title={'Videó megtekintések száma felhasználói csoportok szerint'}
      data={convertData(data)}
      numOfColumns={4}
    />
  )
}

export default ViewsByUserTypeTable
