import React, { PropsWithChildren } from "react"
import styles from "./WhiteGreyOneColumnTable.module.scss"

const WhiteGreyOneColumnTable = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      {React.Children.map(children, (child) => (
        <div className={styles.row}>{child}</div>
      ))}
    </div>
  )
}

export default WhiteGreyOneColumnTable
