import { Notice } from 'components/Notice'
import styles from 'components/AuthModal/ForgottenPasswordContent/ForgottenPasswordContent.module.scss'
import React from 'react'

export const ErrorNotice = () => {
  return (
    <Notice
      className={styles.notice}
      text="Nem sikerült visszaállítani a jelszavát."
      level="error"
    />
  )
}

export const PasswordResetEmailSentNotice = () => {
  return (
    <Notice
      className={styles.notice}
      text="A további teendőket elküldtük a megadott e-mail címére!"
      level="success"
    />
  )
}
