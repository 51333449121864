import { Api } from 'services/index'

export type UserRole = 'Public' | 'Generic' | 'User' | 'Teacher' | 'Admin'

export interface User {
  email: string
  lastName: string
  firstName: string
  roleID: number
  role: UserRole
  gender: string
  genderID: number
  birthYear: number | null
  isSuperAdmin: boolean
}

export interface CreateUserPayload {
  email: string
  password: string
  firstName: string
  lastName: string
  genderId: number | null
  birthYear: number | null
  isTeacher: boolean
}

export interface CreateAdminUserPayload extends CreateUserPayload {
  adminCreationKey: string
}

interface AuthenticateUserPayload {
  email: string
  password: string
}

interface UpdateUserPayload {
  firstName: string
  lastName: string
  genderID: number
  birthYear: number | null
  currentPassword: string | null
  newPassword: string | null
}

interface ForgottenPasswordPayload {
  email: string
}

interface ResetUserPasswordPayload {
  password: string
  token: string | null
}

interface ActivateEmailPayload {
  activationKey: string
}

interface ActivateEmailResponse {
  email: string
}

export const registerUser = (payload: CreateUserPayload) => {
  return Api.post('register', payload)
}

export const registerAdminUser = (payload: CreateAdminUserPayload) => {
  return Api.post<User>('register', payload)
}

export const authenticateUser = (payload: AuthenticateUserPayload) => {
  return Api.post<User>('login', payload)
}

export const logout = () => {
  return Api.post('logout')
}

export const forgottenUserPassword = (payload: ForgottenPasswordPayload) => {
  return Api.post('forgotten-password', payload)
}

export const resetUserPassword = (payload: ResetUserPasswordPayload) => {
  return Api.post<User>('reset-password', payload)
}

export const activateEmail = (payload: ActivateEmailPayload) => {
  return Api.post<ActivateEmailResponse>('activate-email', payload)
}

export const authenticateWithCookie = () => {
  return Api.get<User>('login-cookie')
}

export const updateUser = (payload: UpdateUserPayload) => {
  return Api.patch<User>('user', payload)
}

export const deleteUser = () => {
  return Api.delete('user')
}
