import React, { ReactNode } from 'react'
import { classNames } from 'common/helpers/styling'
import styles from './Table.module.scss'

interface TableProps {
  title?: string
  data: (string | ReactNode)[]
  className?: string
  numOfColumns: number
}

// eslint-disable-next-line react/display-name
const Table = React.forwardRef<HTMLDivElement, TableProps>(
  ({ numOfColumns, data, title, className }, ref) => {
    const isFirstRow = (cellIndex: number) => cellIndex <= numOfColumns - 1

    return (
      <div
        ref={ref}
        style={{ '--numOfColumns': numOfColumns } as React.CSSProperties}
        className={classNames(styles.container, className)}
      >
        {title && <h3>{title}</h3>}
        <div className={styles.table}>
          {data.map((cell, cellIndex) => (
            <div
              key={cellIndex}
              className={classNames(styles.cell, { [styles.firstRow]: isFirstRow(cellIndex) })}
            >
              {cell}
            </div>
          ))}
        </div>
      </div>
    )
  },
)

export default Table
