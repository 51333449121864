import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GreyOverlay } from 'components/GreyOverlay'
import { Card } from 'components/Card'
import { removeTrailingSlash } from 'common/helpers/string'
import { TopSection } from 'components/AuthModal/TopSection'
import { Divider } from 'components/AuthModal/Divider'
import { LoginContent } from 'components/AuthModal/LoginContent'
import { RegistrationContent } from 'components/AuthModal/RegistrationContent'
import { SuccessfulRegistrationContent } from 'components/AuthModal/SuccessfulRegistrationContent'
import { PasswordResetContent } from 'components/AuthModal/PasswordResetContent'
import { ForgottenPasswordContent } from 'components/AuthModal/ForgottenPasswordContent'
import styles from './AuthModal.module.scss'

export interface AuthModalContentProps {
  setAuthModalState: (newState: AuthModalState) => void
  closeAuthModal: () => void
}

export type AuthModalState =
  | 'login'
  | 'register'
  | 'successfulRegistration'
  | 'resetPassword'
  | 'forgottenPassword'

type AuthModalUrls =
  | '/auth/login'
  | '/auth/register'
  | '/auth/successful-registration'
  | '/auth/reset-password'
  | '/auth/forgotten-password'

const authModalUrlMappings: { [key in AuthModalUrls]: AuthModalState } = {
  '/auth/login': 'login',
  '/auth/register': 'register',
  '/auth/successful-registration': 'successfulRegistration',
  '/auth/reset-password': 'resetPassword',
  '/auth/forgotten-password': 'forgottenPassword',
}

const findUrlMapping = (pathname: string): AuthModalState | undefined => {
  const fragment = removeTrailingSlash(pathname.substring(pathname.lastIndexOf('/auth/')))
  return authModalUrlMappings[fragment as AuthModalUrls]
}

const findModalStateMapping = (target: AuthModalState): AuthModalUrls => {
  return Object.keys(authModalUrlMappings).find(
    (k) => authModalUrlMappings[k as AuthModalUrls] === target,
  ) as AuthModalUrls
}

const modalData: {
  [key in AuthModalState]: {
    component: React.FC<AuthModalContentProps>
    title: string
  }
} = {
  login: {
    component: LoginContent,
    title: 'Bejelentkezés',
  },
  register: {
    component: RegistrationContent,
    title: 'Regisztráció',
  },
  successfulRegistration: {
    component: SuccessfulRegistrationContent,
    title: 'Sikeres regisztráció',
  },
  resetPassword: {
    component: PasswordResetContent,
    title: 'Új jelszó beállítása',
  },
  forgottenPassword: {
    component: ForgottenPasswordContent,
    title: 'Elfejeltett jelszó',
  },
}

const AuthModal: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { search } = useLocation()

  const modalState = findUrlMapping(pathname)

  const setModalState = (target: AuthModalState) => {
    const parentUrl = pathname.substring(0, pathname.lastIndexOf('/auth/'))
    navigate(parentUrl + findModalStateMapping(target), { replace: true })
  }

  const closeModal = () => {
    const parentUrl = pathname.substring(0, pathname.lastIndexOf('/auth/'))
    navigate({ pathname: parentUrl, search }, { replace: true })
  }

  if (!modalState) return null

  const ContentElement = modalData[modalState].component

  return (
    <GreyOverlay>
      <Card className={styles.authModal}>
        <TopSection closeAuthModal={closeModal} title={modalData[modalState].title} />
        <Divider marginTop={10} marginBottom={20} />
        <ContentElement setAuthModalState={setModalState} closeAuthModal={closeModal} />
      </Card>
    </GreyOverlay>
  )
}

export default AuthModal
