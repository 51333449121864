import React from 'react'
import { X, Tag } from 'phosphor-react'
import styles from './styles.module.scss'

interface Props {
  name: string
  onDelete: () => void
}

export const DeletableTag = ({ name, onDelete }: Props) => {
  return (
    <div
      className={styles.tag}
    >
      <Tag weight='bold'/>
      <h5>
        {name}
      </h5>
      <X
        onClick={onDelete}
        className={styles.close}
      />
    </div>
  );
};
