import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from 'App'
import { AddAdmin } from 'pages/Admin/AdminStatisticsPage/AddAdmin'
import { AdminsTable } from 'pages/Admin/AdminStatisticsPage/AdminsTable'
import { AdminStatistics } from 'pages/Admin/AdminStatisticsPage/AdminStatistics'
import { Divider } from 'components/AuthModal/Divider'
import { getStatistics } from 'services/statistics.service/statistics.service'
import { StatisticsResponse } from 'services/statistics.service/statistics.types'

const AdminStatisticsPage: React.FC = () => {
  const [statistics, setStatistics] = useState<StatisticsResponse | null>(null)
  const { user, userLoaded } = useContext(UserContext)

  useEffect(() => {
    if (userLoaded) {
      if (user && user.role === 'Admin') {
        getStatistics().then((res) => setStatistics(res.data.payload))
      }
    }
  }, [user, userLoaded])

  return (
    <>
      <AddAdmin />
      <AdminsTable />
      <Divider marginBottom={40} marginTop={50} />
      <AdminStatistics data={statistics} />
    </>
  )
}

export default AdminStatisticsPage
