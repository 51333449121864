import React, { useEffect, useRef, useState } from 'react'
import { OpenableCard } from 'components/OpenableCard'
import { Table } from 'components'
import styles from './OpenableTable.module.scss'

type OpenableTableProps = React.ComponentProps<typeof Table>

const OpenableTable: React.FC<OpenableTableProps> = ({ data, title, numOfColumns, className }) => {
  const [tableHeight, setTableHeight] = useState('0')
  const tableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(`${tableRef.current.clientHeight}px`)
    }
  }, [data, title])

  return (
    <OpenableCard
      initialHeight={'55px'}
      openHeight={`calc(${tableHeight} + 2 * var(--cardPadding))`}
      className={className}
    >
      <Table
        numOfColumns={numOfColumns}
        className={styles.container}
        ref={tableRef}
        data={data}
        title={title}
      />
    </OpenableCard>
  )
}

export default OpenableTable
