import React from 'react'
import { ActionableText } from 'components/ActionableText'
import authModalStyles from 'components/AuthModal/AuthModal.module.scss'
import { AuthModalContentProps } from 'components/AuthModal/AuthModal'
import { UserCirclePlus } from 'phosphor-react'
import { Divider } from 'components/AuthModal/Divider'
import styles from './SuccessfulRegistrationContent.module.scss'

const SuccessfulRegistrationContent: React.FC<AuthModalContentProps> = ({ closeAuthModal }) => {
  return (
    <div className={styles.content}>
      <UserCirclePlus weight="regular" className={styles.icon} />
      <p className={styles.text}>
        Egy megerősítő emailt küldtünk a megadott email címre. Kattintson az üzenetben található
        linkre a fiókja aktiválásához!
      </p>
      <Divider marginTop={20} marginBottom={20} />

      <ActionableText
        className={authModalStyles.actionableText}
        onClick={closeAuthModal}
        text="Ablak bezárása"
      />
    </div>
  )
}

export default SuccessfulRegistrationContent
