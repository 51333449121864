import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  ContentAdmin,
  contentAdminTypeGuard,
  CreatableContentTag,
  UserRolesEnum,
} from "services/content.service/content.types"
import {
  changeContentVideo,
  changeContentVideoThumbnail,
  createTransientContent,
  getContent,
  setContentTags,
} from "services/content.service/content.service"
import { Notice } from "components/Notice"
import { deleteContent } from "services/admin.service/admin.service"
import { Loader } from "components"
import { Helmet } from "react-helmet"
import { Trash } from "phosphor-react"
import { useConfirmationDialog } from "common/hooks/useConfirmationPrompt"
import { DialogOptions } from "common/types/contexts"
import { ContentListContext } from "App"
import TitleRow from "./TitleRow"
import MediaEditor from "./MediaEditor/MediaEditor"
import EditContentBaseData from "./EditContentBaseData/EditContentBaseData"
import { SectionTitle } from "./SectionTitle"
import { TagEditor } from "./TagEditor"
import QuizEditor from "./QuizEditor/QuizEditor"
import styles from "./EditContentPage.module.scss"
import AttachmentEditor from "./AttachmentEditor/AttachmentEditor"

const defaultContentState: ContentAdmin = {
  id: 0,
  title: "Új tananyag",
  description: "",
  attachments: [],
  attachmentCount: 0,
  createdByEmail: "",
  createdDate: 0,
  quiz: [],
  quizCompleted: false,
  seriesId: null,
  seriesIndex: null,
  seriesLength: 0,
  tags: [],
  thumbnailUrl: "",
  videoUrl: "",
  videoDuration: 0,
  userRoleId: UserRolesEnum.Admin,
}

const getDeletionDialogOptions: (action: () => void) => DialogOptions = (action: () => void) => ({
  title: "Tananyag törlése",
  message: "A tananyag törlése végleges, nem visszavonható művelet. Biztosan törölni szeretné?",
  actions: [
    {
      action,
      title: "Törlés",
      variant: "destructive",
      icon: Trash,
    },
    {
      title: "Mégse",
    },
  ],
})

const getDiscardDialogOptions: (action: () => void) => DialogOptions = (action: () => void) => ({
  title: "Módosítások elvetése",
  message:
    "Ha most visszalép, a tananyagon végzett összes módosítás elvész. A művelet nem visszavonható, biztosan ezt szeretné?",
  actions: [
    {
      action,
      title: "Módosítások elvetése",
      variant: "destructive",
      icon: Trash,
    },
    {
      title: "Mégse",
    },
  ],
})

type Changes = "thumbnail" | "video" | "content" | "tags" | "quiz" | "attachments"
export type ChangeSet = Record<Changes, (contentId?: number) => Promise<unknown>>
export type ValidationSet = Record<Changes, () => string | null>

const Section = ({
  children,
  layout = "horizontal",
}: {
  children: React.ReactNode
  layout?: "horizontal" | "vertical"
}) => (
  <div className={layout === "horizontal" ? styles.horizontalSection : styles.verticalSection}>
    {children}
  </div>
)

const EditContentPage = ({ isNewContent = false }: { isNewContent?: boolean }) => {
  const { setContentList } = useContext(ContentListContext)
  const [content, setContent] = useState<ContentAdmin | null>(null)
  const [changes, setChanges] = useState<Partial<ChangeSet>>({})
  const [validations, setValidations] = useState<Partial<ValidationSet>>({})
  const [savingInProgress, setSavingInProgress] = useState(false)
  const [uploadStatus, setUploadStatus] = useState("Feltöltés indítása...")
  const [error, setError] = useState<string | null>(null)
  const { contentId: idParam } = useParams()
  const navigate = useNavigate()

  const canSave = !savingInProgress && Object.keys(changes).length > 0
  const confirmDiscard = useConfirmationDialog(getDiscardDialogOptions(() => navigate(-1)))

  const addToChanges = (key: keyof ChangeSet, value: () => Promise<unknown>) => {
    setError(null)
    setChanges((prev) => ({ ...prev, [key]: value }))
  }

  const removeFromChangeSet = (key: keyof ChangeSet) => {
    setChanges((prev) => {
      const prevCopy = { ...prev }
      delete prevCopy[key]
      return prevCopy
    })
  }

  const addToValidations = (key: keyof ChangeSet, value: () => string | null) => {
    setValidations((prev) => ({ ...prev, [key]: value }))
  }

  const onDiscard = () => {
    if (canSave) {
      confirmDiscard()
    } else {
      navigate(-1)
    }
  }

  const onDelete = async () => {
    if (content?.id) {
      await deleteContent(content.id)
      setContentList(null)
      navigate("/home")
    }
  }

  const confirmDeletion = useConfirmationDialog(getDeletionDialogOptions(onDelete))

  const saveChangesToExistingContent = async () => {
    if (changes.video) {
      setUploadStatus("Videó feltöltése...")
      await changes.video()
    }
    if (changes.thumbnail) {
      setUploadStatus("Előnézeti kép feltöltése...")
      await changes.thumbnail()
    }
    if (changes.tags) {
      setUploadStatus("Címkék beállítása...")
      await changes.tags()
    }
    if (changes.content) {
      setUploadStatus("Tananyag mentése...")
      await changes.content()
    }
    if (changes.quiz) {
      setUploadStatus("Kvíz mentése...")
      await changes.quiz()
    }
    if (changes.attachments) {
      setUploadStatus("Csatolmányok mentése...")
      await changes.attachments()
    }
  }

  const saveChangesToNewContent = async () => {
    if (changes.content) {
      setUploadStatus("Tananyag létrehozása...")
      const { payload: target } = (await createTransientContent()).data

      if (content) {
        setContent({
          ...content,
          id: target.id,
        })
      }

      await changes.content(target.id)

      if (changes.video) {
        setUploadStatus("Videó feltöltése...")
        await changes.video(target.id)
      }
      if (changes.thumbnail) {
        setUploadStatus("Előnézeti kép feltöltése...")
        await changes.thumbnail(target.id)
      }
      if (changes.tags) {
        setUploadStatus("Címkék beállítása...")
        await changes.tags(target.id)
      }
      if (changes.quiz) {
        setUploadStatus("Kvíz mentése...")
        await changes.quiz(target.id)
      }
      if (changes.attachments) {
        setUploadStatus("Csatolmányok mentése...")
        await changes.attachments(target.id)
      }
    }
  }

  const onSave = async () => {
    setSavingInProgress(true)
    if (validations.quiz) {
      const err = validations.quiz()
      if (err) {
        setError(err)
        setSavingInProgress(false)
        return
      }
    }

    // TODO: error handling
    if (isNewContent) {
      await saveChangesToNewContent()
    } else {
      await saveChangesToExistingContent()
    }

    setChanges({})
    setSavingInProgress(false)
    setContentList(null)
    navigate(`/content/${content?.id || idParam}`)
  }

  useEffect(() => {
    if (isNewContent) {
      setContent(defaultContentState)
    } else {
      const loadContent = async (id: number) => {
        try {
          const { payload } = (await getContent(id)).data
          if (!contentAdminTypeGuard(payload)) {
            navigate("/home", { replace: true }) // user is not admin
            return
          }
          setContent(payload)
        } catch {
          navigate("/home", { replace: true })
        }
      }

      if (idParam) {
        const contentId = parseInt(idParam, 10)
        loadContent(contentId)
      }
    }
  }, [idParam, navigate, isNewContent])

  const haveTagsChanged = (newTags: CreatableContentTag[]) => {
    return !(
      content?.tags.every((t) => newTags.find((T) => t.id === T.id)) &&
      newTags?.every((t) => content?.tags.find((T) => t.id === T.id))
    )
  }

  if (!content) {
    return (
      <span className={styles.pageLoader}>
        <Loader size="large" variant="color" />
      </span>
    )
  }

  if (savingInProgress) {
    return (
      <>
        <Helmet>
          <title>Feltöltés - {content.title}</title>
        </Helmet>
        <div className={styles.container}>
          <div className={styles.uploadProgress}>
            <Loader size="large" variant="color" />
            <h2>Mentés folyamatban</h2>
            <h3>{uploadStatus}</h3>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Szerkesztés - {content.title}</title>
      </Helmet>
      <div className={styles.container}>
        <TitleRow
          content={content}
          onBack={onDiscard}
          onDelete={confirmDeletion}
          onSave={onSave}
          canSave={canSave}
          isNewContent={isNewContent}
        />
        {error && <Notice className={styles.notice} level="error" text={error} />}
        <SectionTitle>Alapadatok</SectionTitle>
        <Section layout="vertical">
          <EditContentBaseData
            contentId={content.id}
            currentState={content}
            addToChangeSet={addToChanges}
            removeFromChangeSet={removeFromChangeSet}
          />
        </Section>

        <SectionTitle>Címkék</SectionTitle>
        <TagEditor
          currentTags={content.tags}
          onChange={(tags) => {
            if (haveTagsChanged(tags)) {
              addToChanges("tags", (targetId?: number) =>
                setContentTags(targetId || content.id, tags),
              )
            } else {
              removeFromChangeSet("tags")
            }
          }}
        />

        <SectionTitle>Előnézeti kép & videó</SectionTitle>
        <Section>
          {/* Thumbnail editor */}
          <MediaEditor
            type="image"
            displayUrl={content.thumbnailUrl !== "" ? content.thumbnailUrl : undefined}
            onChange={(file) =>
              addToChanges("thumbnail", (targetId?: number) =>
                changeContentVideoThumbnail(targetId || content.id, file),
              )
            }
          />
          {/* Video editor */}
          <MediaEditor
            type="video"
            displayUrl={content.videoUrl}
            onChange={(file) =>
              addToChanges("video", (targetId?: number) =>
                changeContentVideo(targetId || content.id, file, (event) =>
                  setUploadStatus(
                    `Videó feltöltése (${Math.round((event.loaded / event.total) * 100)}%)`,
                  ),
                ),
              )
            }
          />
        </Section>

        <AttachmentEditor
          addToChangeSet={addToChanges}
          contentId={content.id}
          removeFromChangeSet={removeFromChangeSet}
          attachmentsInitialValue={content.attachments}
        />

        <QuizEditor
          addToChangeSet={addToChanges}
          removeFromChangeSet={removeFromChangeSet}
          contentId={content.id}
          currentQuiz={content.quiz}
          addToValidations={addToValidations}
        />
      </div>
    </>
  )
}

export default EditContentPage
