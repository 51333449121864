import React from 'react'
import { WarningCircle } from 'phosphor-react'
import styles from './FormFieldError.module.scss'

interface Props {
  text: string
}

export const FormFieldError: React.FC<Props> = ({ text }) => {
  return (
    <span className={styles.error}>
      <WarningCircle className={styles.icon} weight="bold" />
      <span>{text}</span>
    </span>
  )
}
