import React, { useContext, useEffect, useState } from 'react'
import OpenableTable from 'pages/Admin/AdminStatisticsPage/AdminsTable/OpenableTable/OpenableTable'
import { AdminData, getAdmins, revokeAdminRights } from 'services/admin.service/admin.service'
import { ApiError } from 'services/apiErrors'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'App'
import { createTableData } from 'pages/Admin/AdminStatisticsPage/AdminsTable/createTableData'
import { RevokeAdminRightPopup } from 'pages/Admin/AdminStatisticsPage/AdminsTable/RemoveAdminRightPopup'
import styles from './AdminsTable.module.scss'

const AdminsTable: React.FC = () => {
  const { user } = useContext(UserContext)

  const [admins, setAdmins] = useState<AdminData[]>([])
  const [emailBeingRevoked, setEmailBeingRevoked] = useState<null | string>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const adminList = (await getAdmins()).data.payload
        setAdmins(adminList)
      } catch (err) {
        if (err instanceof ApiError && err.message === 'invalid credentials') {
          navigate('auth/login')
        }
      }
    }

    fetchAdmins()
  }, [navigate])

  const handleRevokeAttempt = async () => {
    if (!emailBeingRevoked) {
      return
    }
    const freshAdminsList = (await revokeAdminRights({ email: emailBeingRevoked })).data.payload
    setAdmins(freshAdminsList)
    setEmailBeingRevoked(null)
  }

  const columnCount = user?.isSuperAdmin ? 3 : 2

  return (
    <>
      <OpenableTable
        data={createTableData(admins, user?.isSuperAdmin, setEmailBeingRevoked)}
        title={'Adminisztrátorok'}
        numOfColumns={columnCount}
        className={styles.container}
      />
      {emailBeingRevoked && (
        <RevokeAdminRightPopup
          close={() => setEmailBeingRevoked(null)}
          commit={handleRevokeAttempt}
          email={emailBeingRevoked}
        />
      )}
    </>
  )
}

export default AdminsTable
