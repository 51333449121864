import React from 'react'
import {
  QUERY_PARAMS,
  UploadedAfterQueryParam,
  useQueryParamAsState,
} from 'common/hooks/useQueryParamAsState'
import { Dropdown, DropdownOptionType } from 'components/Dropdown/Dropdown'

const uploadedAfterOptions: DropdownOptionType<UploadedAfterQueryParam>[] = [
  { value: 'last_hour', label: 'Elmúlt órában' },
  { value: 'last_day', label: 'Elmúlt napon' },
  { value: 'last_week', label: 'Elmúlt héten' },
  { value: 'last_month', label: 'Elmúlt hónapban' },
  { value: null, label: 'Akármikor' },
]

const UploadedAfterFilter = () => {
  const [uploadedAfter, setUploadedAfter] = useQueryParamAsState(QUERY_PARAMS.uploadedAfter)

  return (
    <Dropdown
      label={'Feltöltés ideje'}
      value={uploadedAfter}
      options={uploadedAfterOptions}
      onChange={setUploadedAfter}
    />
  )
}

export default UploadedAfterFilter
