import { useEffect, useState } from 'react'
import { activateEmail } from 'services/user.service/User.service'
import { ApiError } from 'services/apiErrors'
import { useQueryParams } from 'common/hooks/useQueryParams'

const useActivateEmail = (setEmail: (email: string) => void) => {
  const [activationSuccessful, setActivationSuccessful] = useState<boolean | null>(null)
  const queryParams = useQueryParams()

  useEffect(() => {
    const activationKey = queryParams.get('activation-key')

    if (!activationKey) {
      return
    }

    const handleEmailActivation = async () => {
      try {
        const { email } = (await activateEmail({ activationKey })).data.payload
        setEmail(email)
        setActivationSuccessful(true)
      } catch (err) {
        if (err instanceof ApiError && err.message === 'invalid email activation key') {
          setActivationSuccessful(false)
          return
        }
        throw err
      }
    }

    handleEmailActivation()
  }, [queryParams, setEmail])

  return activationSuccessful
}

export default useActivateEmail
