import React, { useState } from "react"
import { TextArea } from "components"
import { ContentChangableBaseData } from "../EditContentBaseData"
import { SectionSubtitle } from "../../SectionTitle"


interface Props {
  initialValue: string
  updateForSave: (data: ContentChangableBaseData) => void
}

const DescriptionEditor = ({ initialValue, updateForSave }: Props) => {
  const [description, setDescription] = useState(initialValue)

  const onChange = (value: string) => {
    setDescription(value)
    updateForSave({ description: value })
  }

  return (
    <span>
      <SectionSubtitle>Leírás</SectionSubtitle>
      <TextArea
        value={description}
        onChange={onChange}
        placeholder="Adja meg a tananyag leírását..."
      />
    </span>
  )
}

export default DescriptionEditor
