import React from 'react'
import { ContentTag } from 'services/content.service/content.types'
import { useTagIdsQueryParamAsState } from 'common/hooks/useQueryParamAsState'
import { TagButton } from 'pages/ContentPage/TagButton'
import { classNames } from 'common/helpers/styling'
import styles from './TagSelector.module.scss'

interface TagSelectorProps {
  availableTags: ContentTag[]
}

const TagSelector: React.FC<TagSelectorProps> = ({ availableTags }) => {
  const [tagIds] = useTagIdsQueryParamAsState()

  return (
    <div>
      <p className={styles.title}>Címkék</p>
      <div className={styles.container}>
        {availableTags.map((tag) => (
          <TagButton
            className={classNames(styles.tagButton, {
              [styles.selected]: tagIds && tagIds.find((id) => id === tag.id),
            })}
            key={tag.id}
            tag={tag}
          />
        ))}
      </div>
    </div>
  )
}

export default TagSelector
