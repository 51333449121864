import React, { ReactNode } from 'react'
import styles from './styles.module.scss'

export const SectionTitle = ({ children }: { children: ReactNode; }) => (
  <h3 className={ styles.title }>
    { children }
  </h3>
)

export const SectionSubtitle = ({ children }: { children: ReactNode; }) => (
  <h4 className={ styles.subtitle }>
    { children }
  </h4>
)
