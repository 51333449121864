import React from 'react'
import { Tag } from 'phosphor-react'
import { useNavigate } from 'react-router-dom'
import { ContentTag } from 'services/content.service/content.types'
import { classNames } from 'common/helpers/styling'
import { useQueryParams } from 'common/hooks/useQueryParams'
import { getTagIdArrayFromCSV, QUERY_PARAMS } from 'common/hooks/useQueryParamAsState'
import styles from './TagButton.module.scss'

interface Props {
  tag: ContentTag
  className?: string
}

export const TagButton: React.FC<Props> = ({ tag, className }) => {
  const navigate = useNavigate()
  const query = useQueryParams()
  const handleClick = () => {
    const tagIdsArray = getTagIdArrayFromCSV(query.get(QUERY_PARAMS.tagIds))

    if (!tagIdsArray.length || !tagIdsArray.includes(tag.id)) {
      const currValue = query.get(QUERY_PARAMS.tagIds)
      const newTagIdsString = `${currValue ? `${currValue},` : ''}${tag.id}`
      query.set(QUERY_PARAMS.tagIds, newTagIdsString)
    } else {
      const newTagIdsString = tagIdsArray.filter((id) => id !== tag.id).join(',')
      query.set(QUERY_PARAMS.tagIds, newTagIdsString)
    }
    navigate({ pathname: '/home', search: query.toString() })
  }

  return (
    <div title={tag.name} className={classNames(styles.tag, className)} onClick={handleClick}>
      <Tag weight="bold" className={styles.icon} />
      <span className={styles.text}>{tag.name}</span>
    </div>
  )
}
