const userGenericTypesTuple = ['male', 'female', 'under_25', 'between_25_50', 'over_50'] as const

const userTypesByRegistrationTuple = ['not_registered', 'registered', 'teacher'] as const

export const userGroupsTuple = [...userTypesByRegistrationTuple, ...userGenericTypesTuple]
export type UserGroups = typeof userGroupsTuple[number]

type ResultsByUserType = Record<UserGroups, number>

interface ResultsByContent {
  [key: string]: number
}

export const registrationTypesTuple = ['last_month', 'sum', ...userGenericTypesTuple] as const
type RegistrationResults = Record<typeof registrationTypesTuple[number], number>

export interface StatisticsResponse {
  results_by_users: ResultsByUserType
  results_by_content: ResultsByContent
  registration: {
    teacher: RegistrationResults
    generic: RegistrationResults
  }
  quiz_general: {
    successful: ResultsByUserType
    unsuccessful: ResultsByUserType
  }
  quiz_content_specific: {
    successful: ResultsByContent
    unsuccessful: ResultsByContent
  }
}
