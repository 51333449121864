import axios, { AxiosError } from 'axios'
import { ApiErrorPayload } from 'services/index'

type ErrorMessage =
  | 'email already taken'
  | 'invalid request body'
  | 'invalid credentials'
  | 'invalid email activation key'
  | 'invalid session'
  | 'invalid key'
  | 'already admin'
  | 'pending invitation'
  | 'invalid revoke request'

export class ApiError {
  message: ErrorMessage
  statusCode: number
  errorCode: number
  axiosError: AxiosError

  constructor(err: Error) {
    if (!ApiError.isErrorApiRelated(err)) {
      throw err
    }
    this.axiosError = err
    this.message = err.response.data.data.message as ErrorMessage
    this.statusCode = err.response.status
    this.errorCode = err.response.data.data.errorCode
  }

  static isErrorApiRelated(err: unknown): err is Required<AxiosError<ApiErrorPayload>> {
    return (
      axios.isAxiosError(err) &&
      err.response?.data.data.message &&
      err.response.data.result === 'error'
    )
  }
}
