import React from 'react'
import { StatisticsResponse } from 'services/statistics.service/statistics.types'
import ViewsByUserTypeTable from 'pages/Admin/AdminStatisticsPage/AdminStatistics/ViewsByUserTypeTable/ViewsByUserTypeTable'
import RegistrationStatisticsTable from 'pages/Admin/AdminStatisticsPage/AdminStatistics/RegistrationStatisticsTable/RegistrationStatisticsTable'
import StatisticsByContent from 'pages/Admin/AdminStatisticsPage/AdminStatistics/StatisticsByContentTable/StatisticsByContentTable'
import { Loader } from 'components'
import styles from './AdminStatistics.module.scss'

interface AdminStatisticsProps {
  data: StatisticsResponse | null
}

const AdminStatistics: React.FC<AdminStatisticsProps> = ({ data }) => {
  if (!data) {
    return <Loader size={'large'} className={styles.loader} />
  }

  return (
    <div className={styles.container}>
      <h3>Statisztikák</h3>
      <ViewsByUserTypeTable data={data} />
      <RegistrationStatisticsTable data={data} />
      <StatisticsByContent data={data} />
    </div>
  )
}

export default AdminStatistics
