/* eslint-disable no-useless-escape */
export const isValidEmail = (email: string) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}
/* eslint-enable */

export const isValidLength = (str: string, { min, max }: { min?: number; max?: number }) => {
  return !((max && str.length > max) || (min && str.length < min))
}
