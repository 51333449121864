import { stylingHelpers } from 'common/helpers'
import { Icon as IconType, IconWeight } from 'phosphor-react'
import React from 'react'
import styles from './ContentLabel.module.scss'

interface Props {
  label?: string
  Icon: IconType
  iconWeight?: IconWeight
  variant?: 'default' | 'primary' | 'overlay' | 'green'
  size?: 'normal' | 'small'
}

export const ContentLabel: React.FC<Props> = ({
  label,
  Icon,
  iconWeight,
  variant = 'default',
  size = 'normal',
}) => {
  return (
    <div
      className={stylingHelpers.classNames(styles.contentlabel, styles[variant], {
        [styles.small]: size === 'small',
      })}
    >
      <Icon
        weight={iconWeight || 'bold'}
        className={[styles.icon, !label && styles.nomargin].join(' ')}
      />
      {label}
    </div>
  )
}
