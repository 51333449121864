import { Api } from 'services/index'

export interface AdminData {
  firstName: string
  lastName: string
  email: string
}

const baseUrl = 'admin/'

export const inviteAdmin = (email: string) => {
  return Api.post(`${baseUrl}invite`, { email })
}

export const validateAdminCreationKey = (key: string) => {
  return Api.get<{ email: string }>(`${baseUrl}invitations/${key}`)
}

export const getAdmins = () => {
  return Api.get<AdminData[]>(`${baseUrl}`)
}

export const revokeAdminRights = (data: { email: string }) => {
  return Api.post<AdminData[]>(`${baseUrl}revoke-rights`, data)
}

export const deleteContent = (contentId: number) => {
  return Api.delete(`${baseUrl}content/${contentId}`)
}
