import React, { useContext, useState } from 'react'
import { Divider } from 'components/AuthModal/Divider'
import { PasswordInput, TextInput } from 'components'
import { Button } from 'components/Button'
import authModalStyles from 'components/AuthModal/AuthModal.module.scss'
import { ActionableText } from 'components/ActionableText'
import { authenticateUser } from 'services/user.service/User.service'
import { ApiError } from 'services/apiErrors'
import { isValidEmail } from 'common/helpers/validation'
import { AuthModalContentProps } from 'components/AuthModal/AuthModal'
import useActivateEmail from 'components/AuthModal/LoginContent/useActivateEmail'
import {
  FailedEmailActivationNotice,
  SuccessfulEmailActivationNotice,
  InvalidCredentialsNotice,
} from 'components/AuthModal/LoginContent/LoginContentNotices'
import { UserContext } from 'App'
import styles from './LoginContent.module.scss'

type error = 'emailEmpty' | 'emailInvalid' | 'passwordEmpty' | 'invalidCredentials'

const validate = (data: { email: string; password: string }): error[] => {
  const errors = [] as error[]
  if (data.email === '') {
    errors.push('emailEmpty')
  } else if (!isValidEmail(data.email)) {
    errors.push('emailInvalid')
  }
  if (data.password === '') {
    errors.push('passwordEmpty')
  }
  return errors
}

const errorMessages: { [key in error]: string } = {
  emailEmpty: 'Kötelező mező',
  emailInvalid: 'A megadott email cím nem megfelelő',
  passwordEmpty: 'Kötelező mező',
  invalidCredentials: 'Hibás email cím vagy jelszó',
}

const LoginContent: React.FC<AuthModalContentProps> = ({ closeAuthModal, setAuthModalState }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<error[]>([])

  const { setUser } = useContext(UserContext)

  const emailActivationSuccessful = useActivateEmail(setEmail)

  const loginPayload = {
    email,
    password,
  }

  const login = async () => {
    const validationErrors = validate({ password, email })
    setErrors(validationErrors)
    if (validationErrors.length) return

    try {
      const { payload } = (await authenticateUser(loginPayload)).data
      setUser(payload)
      closeAuthModal()
    } catch (err) {
      if (err instanceof ApiError && err.message === 'invalid credentials') {
        setErrors(['invalidCredentials'])
      }
      throw err
    }
  }

  const getError = (errorName: error) => {
    return (errors.includes(errorName) || undefined) && errorMessages[errorName]
  }

  return (
    <>
      <TextInput
        label="Email cím"
        value={email}
        onChange={setEmail}
        errorText={getError('emailEmpty') || getError('emailInvalid')}
      />
      <PasswordInput
        label="Jelszó"
        value={password}
        onChange={setPassword}
        errorText={getError('passwordEmpty')}
        onEnter={login}
      />
      <ActionableText
        className={styles.actionableText}
        onClick={() => setAuthModalState('forgottenPassword')}
        text="Elfejeltett jelszó"
      />

      {emailActivationSuccessful && <SuccessfulEmailActivationNotice />}
      {emailActivationSuccessful === false && <FailedEmailActivationNotice />}
      {getError('invalidCredentials') && <InvalidCredentialsNotice />}

      <Button
        onClick={login}
        variant="primary"
        title="Bejelentkezés"
        className={authModalStyles.button}
      />

      <Divider marginTop={20} marginBottom={20} />
      <ActionableText
        className={authModalStyles.actionableText}
        onClick={() => setAuthModalState('register')}
        text="Új fiók létrehozása"
      />
    </>
  )
}

export default LoginContent
