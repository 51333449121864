import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { getenv } from 'common/helpers'
import { deleteUser } from 'services/user.service/User.service'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components'
import { ArrowLeft } from 'phosphor-react'
import { UserContext } from 'App'
import styles from './ProfileSettingsPage.module.scss'
import ProfileForm from './ProfileForm'

const ProfileSettingsPage: React.FC = () => {
  const navigate = useNavigate()
  const { user, userLoaded, setUser } = useContext(UserContext)
  const [deleteConfirmationSent, setDeleteConfirmationSent] = useState(false)

  useEffect(() => {
    if (userLoaded && !user) navigate('/home')
  }, [navigate, user, userLoaded])

  const onDeleteUser = async () => {
    try {
      await deleteUser()
      setDeleteConfirmationSent(true)
    } catch {
      // pass?
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${getenv('HELMET_TITLE')}`} | Beállítások</title>
      </Helmet>
      <div className={styles.layoutHelper}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>Profil módosítása</h2>
            <Button
              className={styles.button}
              Icon={ArrowLeft}
              iconWeight="bold"
              title="Vissza"
              onClick={() => navigate(-1)}
            />
          </div>
          {user && <ProfileForm user={user} setUser={setUser} />}
        </div>
        {user && (
          <div className={styles.container}>
            <div className={styles.title}>
              <h2>Fiók törlése</h2>
            </div>
            <p className={styles.disclaimer}>
              Személyes Tudástár fiókjának törlése nem visszavonható. A &quot;Fiók törlése&quot;
              gombra kattintva egy megerősítő e-mailt küldünk Önnek, fiókja törlését az abban
              találató link-re kattintva véglegesítheti.
            </p>
            <Button
              variant="destructive"
              className={styles.deleteUserButton}
              onClick={onDeleteUser}
              title={deleteConfirmationSent ? 'Fiók törlése kezdeményezve' : 'Fiók törlése'}
              disabled={deleteConfirmationSent}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ProfileSettingsPage
