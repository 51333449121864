import React, { ReactElement } from 'react'
import { ProjectorScreen, ImageSquare, FilePdf } from 'phosphor-react'
import { Attachment, AttachmentType } from 'services/content.service/content.types'
import styles from './AttachmentButton.module.scss'

interface Props {
  attachment: Attachment
}

export const AttachmentButton: React.FC<Props> = ({ attachment }) => {
  const icons: { [key in AttachmentType]: ReactElement } = {
    image: <ImageSquare weight="bold" className={styles.icon} />,
    document: <FilePdf weight="bold" className={styles.icon} />,
    presentation: <ProjectorScreen weight="bold" className={styles.icon} />,
  }

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={attachment.url}
      title={attachment.title}
      className={styles.attachment}
    >
      {icons[attachment.type]}
      <span className={styles.text}>{attachment.title}</span>
    </a>
  )
}
