import React from 'react'
import { Card } from 'components'
import styles from './Footer.module.scss'

export const Footer: React.FC = () => {
  return (
    <footer>
      <Card className={styles.footer}>
        <div className={styles.logo}>
          <div className={styles.image} />
          <div className={styles.text}>
            <h1>Tudástár</h1>
            <h2>Édes Gyermekünk Alapítvány</h2>
          </div>
        </div>
      </Card>
    </footer>
  )
}
