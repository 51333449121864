import React from "react"
import { Icon as IconType, IconWeight } from "phosphor-react"
import { FormFieldError } from "components"
import TextareaAutosize from "react-textarea-autosize"
import styles from "./TextArea.module.scss"

interface Props {
  value: string
  onChange?: (value: string) => void
  StartIcon?: IconType
  startIconWeight?: IconWeight
  EndIcon?: IconType
  endIconWeight?: IconWeight
  label?: string
  placeholder?: string
  disabled?: boolean
  multiline?: boolean
  maxLines?: number
  maxLength?: number
  errorText?: string
  className?: string
  inputClassName?: string
  minRows?: number
  maxRows?: number
}

export const TextArea: React.FC<Props> = ({
  value,
  onChange,
  StartIcon,
  startIconWeight,
  EndIcon,
  endIconWeight,
  label,
  placeholder,
  disabled,
  maxLength,
  errorText,
  className,
  inputClassName,
  minRows = 3,
  maxRows = 50,
}) => {
  return (
    <div
      className={[
        styles.container,
        errorText && styles.error,
        disabled && styles.disabled,
        className,
      ].join(" ")}
    >
      {label && <label>{label}</label>}
      <div className={[styles.textInput, StartIcon && styles.noPadding, inputClassName].join(" ")}>
        {StartIcon && (
          <span className={styles.startIcon}>
            <StartIcon weight={startIconWeight || "bold"} />
          </span>
        )}
        <TextareaAutosize
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          onChange={(e) => onChange?.(e.target.value)}
          placeholder={placeholder}
          minRows={minRows}
          maxRows={maxRows}
        />
        {EndIcon && (
          <span className={styles.endIcon}>
            <EndIcon weight={endIconWeight || "bold"} />
          </span>
        )}
      </div>
      {errorText && <FormFieldError text={errorText} />}
    </div>
  )
}
