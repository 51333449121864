import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { PageBase } from "components"
import { HomePage, ContentPage, ProfileSettingsPage } from "pages"
import { ApiError } from "services/apiErrors"
import { authenticateWithCookie, User } from "services/user.service/User.service"
import {
  DialogOptions,
  DialogContextType,
  UserContextType,
  ContentListContextType,
} from "common/types/contexts"
import { AdminStatisticsPage } from "pages/Admin/AdminStatisticsPage"
import { AdminPageBase } from "pages/Admin/PageBase"
import { EditContentPage } from "pages/Admin/EditContentPage"
import { Dialog } from "components/Dialog"
import { ContentSummary } from "services/content.service/content.types"

export const UserContext = React.createContext({} as UserContextType)
export const DialogContext = React.createContext({} as DialogContextType)
export const ContentListContext = React.createContext({} as ContentListContextType)

const useCheckCookie = (
  setUser: (user: User) => void,
  setUserLoaded: (loaded: boolean) => void,
) => {
  useEffect(() => {
    const checkCookie = async () => {
      try {
        const { payload } = (await authenticateWithCookie()).data
        setUser(payload)
      } catch (err) {
        if (!(err instanceof ApiError && err.message === "invalid session")) {
          throw err
        }
      } finally {
        setUserLoaded(true)
      }
    }

    checkCookie()
  }, [setUser, setUserLoaded])
}

function App() {
  const [user, setUser] = useState<User | null>(null)
  const [userLoaded, setUserLoaded] = useState(false)
  const [dialogOptions, setDialogOptions] = useState<DialogOptions | null>(null)
  const [contentList, setContentList] = useState<ContentSummary[] | null>(null)
  const [lastContentQuery, setLastContentQuery] = useState<string | null>(null)

  useCheckCookie(setUser, setUserLoaded)

  const userContext: UserContextType = {
    user,
    userLoaded,
    setUser,
  }

  const dialogContext: DialogContextType = {
    options: dialogOptions,
    setOptions: setDialogOptions,
  }

  const contentListContext: ContentListContextType = {
    contentList,
    setContentList,
    lastQuery: lastContentQuery,
    setLastQuery: setLastContentQuery,
  }

  return (
    <DialogContext.Provider value={dialogContext}>
      <UserContext.Provider value={userContext}>
        <ContentListContext.Provider value={contentListContext}>
          <Router>
            <Routes>
              <Route path={"/*"} element={<PageBase />}>
                <Route path="home/*" element={<HomePage />} />
                <Route path="content/:contentId/*" element={<ContentPage />} />
                <Route path="profile/*" element={<ProfileSettingsPage />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </Route>
              <Route path="admin/*" element={<AdminPageBase />}>
                <Route path={"statistics/*"} element={<AdminStatisticsPage />} />
                <Route path={"edit/:contentId/*"} element={<EditContentPage />} />
                <Route path={"upload/*"} element={<EditContentPage isNewContent/>} />
                <Route path={"*"} element={<AdminStatisticsPage />} />
              </Route>
            </Routes>
          </Router>
        </ContentListContext.Provider>
      </UserContext.Provider>
      <Dialog />
    </DialogContext.Provider>
  )
}

export default App
