import React, { useState } from 'react'
import { Icon as IconType, IconWeight, Eye, EyeClosed } from 'phosphor-react'
import { FormFieldError } from 'components'
import styles from './TextInput.module.scss'
import customStyles from './PasswordInput.module.scss'

interface Props {
  value: string
  onChange: (value: string) => void
  label?: string
  StartIcon?: IconType
  startIconWeight?: IconWeight
  disabled?: boolean
  maxLength?: number
  errorText?: string
  className?: string
  onEnter?: () => void
}

export const PasswordInput: React.FC<Props> = ({
  value,
  onChange,
  label,
  StartIcon,
  startIconWeight,
  disabled,
  maxLength,
  errorText,
  className,
  onEnter,
}) => {
  const [peek, setPeek] = useState(false)

  const onPeek = () => {
    setPeek(!peek)
  }

  return (
    <div className={[styles.container, errorText && styles.error, className].join(' ')}>
      {label && <label>{label}</label>}
      <div className={[styles.textInput, disabled && styles.disabled].join(' ')}>
        {StartIcon && (
          <span className={styles.startIcon}>
            <StartIcon weight={startIconWeight || 'bold'} />
          </span>
        )}
        <input
          value={value}
          maxLength={maxLength}
          type={!peek ? 'password' : 'text'}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          placeholder="••••••••••"
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onEnter?.()
            }
          }}
        />
        <span className={customStyles.peekButton} onClick={onPeek}>
          {peek ? <EyeClosed weight="bold" /> : <Eye weight="bold" />}
        </span>
      </div>
      {errorText && <FormFieldError text={errorText} />}
    </div>
  )
}
