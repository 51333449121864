import React, { useState } from "react"
import { TextInput } from "components"
import { PencilSimple } from "phosphor-react"
import { ContentChangableBaseData } from "../EditContentBaseData"
import { SectionSubtitle } from "../../SectionTitle"


interface Props {
  initialValue: string
  updateForSave: (data: ContentChangableBaseData) => void
}

const TitleEditor = ({ initialValue, updateForSave }: Props) => {
  const [title, setTitle] = useState(initialValue)

  const onChange = (value: string) => {
    setTitle(value)
    updateForSave({ title: value })
  }

  return (
    <span>
      <SectionSubtitle>Cím</SectionSubtitle>
      <TextInput
        EndIcon={PencilSimple}
        endIconWeight="fill"
        value={title}
        onChange={onChange}
        placeholder="Adja meg a tananyag címét..."
      />
    </span>
  )
}

export default TitleEditor
