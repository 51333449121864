import { useEffect, useState } from 'react'
import { useQueryParams } from 'common/hooks/useQueryParams'
import { validateAdminCreationKey } from 'services/admin.service/admin.service'
import { ApiError } from 'services/apiErrors'

const useAdminRegistration = (setEmail: (email: string) => void) => {
  const [adminCreationKey, setAdminCreationKey] = useState<null | string>(null)
  const [isInvalidAdminKey, setIsInvalidAdminKey] = useState(false)
  const [validatingKey, setValidatingKey] = useState(false)

  const params = useQueryParams()

  useEffect(() => {
    const handleAdminCreationKeyValidation = async (key: string) => {
      setValidatingKey(true)
      try {
        const { email: adminEmail } = (await validateAdminCreationKey(key)).data.payload
        setEmail(adminEmail)
        setAdminCreationKey(key)
      } catch (err) {
        if (err instanceof ApiError && err.message === 'invalid key') {
          setIsInvalidAdminKey(true)
        } else {
          throw err
        }
      } finally {
        setValidatingKey(false)
      }
    }

    const key = params.get('admin-creation-key')
    if (key) {
      handleAdminCreationKeyValidation(key)
    }
  }, [params, setEmail])

  return { isInvalidAdminKey, adminCreationKey, validatingKey }
}

export default useAdminRegistration
