import React from 'react'
import { WarningCircle, Info, CheckCircle } from 'phosphor-react'
import { classNames } from 'common/helpers/styling'
import styles from './Notice.module.scss'

const icons = {
  info: Info,
  warning: WarningCircle,
  success: CheckCircle,
  error: WarningCircle,
}

interface NoticeProps {
  level: 'info' | 'warning' | 'success' | 'error'
  text: string
  className?: string
}

const Notice: React.FC<NoticeProps> = ({ level, text, className }) => {
  const Icon = icons[level]

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.info]: level === 'info',
        [styles.warning]: level === 'warning',
        [styles.success]: level === 'success',
        [styles.error]: level === 'error',
      })}
    >
      <Icon color="var(--main-color)" />
      <p>{text}</p>
    </div>
  )
}

export default Notice
