import React from 'react'
import { Oval } from 'react-loader-spinner'
import consts from 'styles/_common.scss'

interface Props {
  variant?: 'default' | 'primary' | 'color' | 'destructive' | 'submit'
  size?: 'small' | 'large'
  className?: string
}

export const Loader: React.FC<Props> = ({ variant = 'default', size = 'small', className }) => {
  let loaderSize = '15px'
  let strokeWidth = 3

  switch (size) {
    case 'small':
      loaderSize = '18px'
      strokeWidth = 8
      break
    case 'large':
      loaderSize = '4rem'
      strokeWidth = 4
      break
    default:
      break
  }

  switch (variant) {
    case 'color':
      return (
        <Oval
          width={loaderSize}
          height={loaderSize}
          strokeWidth={strokeWidth}
          color={consts.purple}
          secondaryColor={consts.purpleLightHint}
          wrapperClass={className}
        />
      )
    case 'primary':
      return (
        <Oval
          width={loaderSize}
          height={loaderSize}
          strokeWidth={strokeWidth}
          color={consts.white}
          secondaryColor={consts.whiteHint}
          wrapperClass={className}
        />
      )
    case 'destructive':
      return (
        <Oval
          width={loaderSize}
          height={loaderSize}
          strokeWidth={strokeWidth}
          color={consts.red}
          secondaryColor={consts.redHint}
          wrapperClass={className}
        />
      )
    case 'submit':
      return (
        <Oval
          width={loaderSize}
          height={loaderSize}
          strokeWidth={strokeWidth}
          color={consts.white}
          secondaryColor={consts.whiteHint}
          wrapperClass={className}
        />
      )
    default:
      return (
        <Oval
          width={loaderSize}
          height={loaderSize}
          strokeWidth={strokeWidth}
          color={consts.black}
          secondaryColor={consts.blackHint}
          wrapperClass={className}
        />
      )
  }
}
