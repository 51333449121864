import React from 'react'
import { StatisticsResponse } from 'services/statistics.service/statistics.types'
import OpenableTable from 'pages/Admin/AdminStatisticsPage/AdminsTable/OpenableTable/OpenableTable'

interface StatisticsByContentProps {
  data: StatisticsResponse
}

const tableHeader = [
  'Videó neve',
  'Megtekintések száma',
  'Sikeres kvíz kitöltés',
  'Sikertelen kvíz kitöltés',
]

const convertData = (data: StatisticsResponse) => {
  const results: (number | string)[] = [...tableHeader]

  Object.keys(data.results_by_content).forEach((key) => {
    results.push(key)
    results.push(data.results_by_content[key] || 0)
    results.push(data.quiz_content_specific.successful[key] || 0)
    results.push(data.quiz_content_specific.unsuccessful[key] || 0)
  })

  return results
}

const StatisticsByContent: React.FC<StatisticsByContentProps> = ({ data }) => {
  return <OpenableTable data={convertData(data)} numOfColumns={4} title={'Tananyagok'} />
}

export default StatisticsByContent
