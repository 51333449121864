import React, { ReactElement, useState } from 'react'
import { Loader } from 'components'
import { classNames } from 'common/helpers/styling'
import { IconProps, IconWeight } from 'phosphor-react'
import styles from './Button.module.scss'

interface Props {
  title?: string
  Icon?: (props: IconProps) => ReactElement | null
  iconWeight?: IconWeight
  variant?: 'default' | 'primary' | 'destructive' | 'submit'
  optOutOfAutoLoading?: boolean
  disabled?: boolean
  className?: string
  onClick: () => void | Promise<void>
  pressed?: boolean
}

export const Button: React.FC<Props> = ({
  title,
  Icon,
  iconWeight = 'fill',
  variant = 'default',
  disabled,
  onClick,
  className,
  optOutOfAutoLoading = false,
  pressed,
}) => {
  const [loading, setLoading] = useState(false)

  const onClickWithLoading = async () => {
    const res = onClick()
    if (res instanceof Promise && !optOutOfAutoLoading) {
      setLoading(true)
      try {
        await res
      } finally {
        setLoading(false)
      }
    }
  }

  let startAdornment = Icon && (
    <Icon
      className={[styles.startAdornment, !title && styles.nomargin].join(' ')}
      weight={iconWeight}
    />
  )

  if (loading)
    startAdornment = (
      <span className={[styles.startAdornment, !title && styles.nomargin].join(' ')}>
        <Loader size="small" variant={variant} />
      </span>
    )

  return (
    <div
      className={classNames(
        styles.button,
        styles[variant],
        className,
        {
          [styles.disabled]: !!disabled,
        },
        {
          [styles.pressed]: pressed,
        },
      )}
      onClick={disabled || loading ? undefined : onClickWithLoading}
    >
      {startAdornment}
      <p>{title}</p>
    </div>
  )
}
