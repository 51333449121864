import { useEffect, useRef } from 'react'

export function useHandleClickOutside<T extends HTMLElement>(handleClickOutside: () => void) {
  const ref = useRef<T>(null)

  useEffect(() => {
    function handleClick(event: Event) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleClickOutside()
      }
    }
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [handleClickOutside])

  return { ref }
}
