import React, { useState } from 'react'
import { Button, TextInput } from 'components'
import { validationHelpers } from 'common/helpers'
import { inviteAdmin as callInviteAdminApi } from 'services/admin.service/admin.service'
import { ApiError } from 'services/apiErrors'
import { Notice } from 'components/Notice'
import styles from './AddAdmin.module.scss'

interface Message {
  text: string
  type: 'error' | 'success'
}

const AddAdmin: React.FC = () => {
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [message, setMessage] = useState<Message | null>(null)

  const onEmailChange = (newEmail: string) => {
    setEmail(newEmail)
    setMessage(null)

    if (validationHelpers.isValidEmail(newEmail)) {
      setIsValidEmail(true)
      return
    }
    setIsValidEmail(false)
  }

  const inviteAdmin = async () => {
    try {
      await callInviteAdminApi(email)
      setMessage({ type: 'success', text: `${email} sikeresen mehívva adminisztrátornak` })
    } catch (err) {
      if (err instanceof ApiError) {
        if (err.message === 'already admin') {
          setMessage({ type: 'error', text: `${email} már rendelkezik adminisztrátori jogokkal` })
        } else if (err.message === 'pending invitation') {
          setMessage({ type: 'error', text: `${email} már rendelkezik meghívóval` })
        }
        return
      }
      throw err
    }
  }

  return (
    <div className={styles.container}>
      <h3>Adminisztrátor hozzáadása</h3>
      <div className={styles.flexRow}>
        <TextInput
          className={styles.input}
          value={email}
          onChange={onEmailChange}
          placeholder={'E-mail cím'}
        />
        <Button
          className={styles.button}
          onClick={inviteAdmin}
          title={'Meghívás'}
          variant={'primary'}
          disabled={!isValidEmail}
        />
      </div>
      {message && <Notice className={styles.notice} level={message.type} text={message.text} />}
    </div>
  )
}

export default AddAdmin
