import React, { useState } from 'react'
import { Card } from 'components/Card'
import { classNames } from 'common/helpers/styling'
import { X as CloseIcon } from 'phosphor-react'
import styles from './OpenableCard.module.scss'

interface OpenableCardProps {
  children?: React.ReactNode
  initialHeight: string
  openHeight: string
  className?: string
}

const OpenableCard = ({
  initialHeight,
  openHeight,
  children,
  className,
}: OpenableCardProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Card
      className={classNames(styles.container, className, {
        [styles.open]: isOpen,
      })}
      style={
        { '--initialHeight': initialHeight, '--openHeight': openHeight } as React.CSSProperties
      }
      onClick={() => !isOpen && setIsOpen(true)}
    >
      {isOpen && (
        <CloseIcon
          size={20}
          onClick={() => setIsOpen(false)}
          weight={'bold'}
          className={styles.closeIcon}
        />
      )}
      {children}
    </Card>
  )
}

export default OpenableCard
