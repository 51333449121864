import React, { useEffect, useState, useRef, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Check, Clock, File, Play, PencilSimple, Parachute } from "phosphor-react"
import { Button, ContentCard, ContentLabel, Loader } from "components"
import { stringHelpers, stylingHelpers } from "common/helpers"
import { getContent, getRelatedContent } from "services/content.service/content.service"
import { Quiz } from "pages/ContentPage/Quiz"
import { AttachmentButton } from "pages/ContentPage/AttachmentButton"
import { AttachmentType, Content, ContentSummary } from "services/content.service/content.types"
import { Helmet } from "react-helmet"
import { useMediaQuery } from "react-responsive"
import { useResetContentScrollPosition } from "common/hooks/useResetContentScrollPosition"
import { UserContext } from "App"
import styles from "./ContentPage.module.scss"
import { TagButton } from "./TagButton"

const ContentPage: React.FC = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const { contentId: idParam } = useParams()
  const [content, setContent] = useState<Content | null>(null)
  const [paused, setPaused] = useState(true)
  const player = useRef() as React.MutableRefObject<HTMLVideoElement>
  const [shouldShowQuiz, setShouldShowQuiz] = useState(false)
  const [relatedContents, setRelatedContents] = useState<ContentSummary[] | null>(null)
  const isSmallScreen = useMediaQuery({ query: "screen and (max-width: 520px)" })

  const resetPageScroll = useResetContentScrollPosition()

  useEffect(() => {
    const loadRelatedContents = async (id: number) => {
      try {
        const { payload } = (await getRelatedContent(id)).data
        setRelatedContents(payload)
      } catch {
        setRelatedContents([])
      }
    }

    const loadContent = async (id: number) => {
      try {
        const { payload } = (await getContent(id)).data
        setContent(payload)
        loadRelatedContents(id)
      } catch {
        navigate("/home", { replace: true })
      }
    }

    if (idParam) {
      const contentId = parseInt(idParam, 10)
      loadContent(contentId)
    }
  }, [idParam, navigate])

  const handlePlayButton = () => {
    if (player) {
      if (isSmallScreen) player.current.requestFullscreen()
      setPaused(false)
      player.current.play()
    }
  }

  const resetState = () => {
    resetPageScroll()
    setContent(null)
    setRelatedContents(null)
  }

  if (!content) {
    return (
      <span className={styles.loader}>
        <Loader size="large" variant="color" />
      </span>
    )
  }

  let relatedContentList = (
    <span className={styles.loader}>
      <Loader size="large" variant="color" />
    </span>
  )

  if (relatedContents) {
    relatedContentList =
      relatedContents.length === 0 ? (
        <h3>Nincs kapcsolódó tananyag</h3>
      ) : (
        <div className={styles.relatedContentList}>
          {relatedContents.map((c) => (
            <ContentCard
              key={`related-${c.id}`}
              content={c}
              className={styles.contentCard}
              onSelect={resetState}
            />
          ))}
        </div>
      )
  }

  const attachmentsByType = (type: AttachmentType) => {
    const attachments = content.attachments.filter((a) => a.type === type)
    if (attachments.length === 0) return null

    return (
      <div className={styles.attachmentGrid}>
        {attachments.map((a) => (
          <AttachmentButton key={`${a.type}-${a.title}`} attachment={a} />
        ))}
      </div>
    )
  }

  const onVideoEnd = async () => {
    try {
      await document.exitFullscreen()
    } catch {
      // Not in fullscreen
    }

    if (!content.quiz?.length) {
      return
    }
    setTimeout(() => {
      setShouldShowQuiz(true)
    }, 500)
  }

  return (
    <>
      <Helmet>
        <title>{content.title}</title>
      </Helmet>
      <div className={styles.container}>
        {/* Player */}
        <div className={stylingHelpers.classNames(styles.player, { [styles.paused]: paused })}>
          <video
            ref={player}
            src={content.videoUrl}
            controls
            onPause={() => setPaused(true)}
            onPlay={() => setPaused(false)}
            onEnded={onVideoEnd}
          />
          <div className={styles.overlay} onClick={handlePlayButton}>
            <h1>{content.title}</h1>
            <span className={styles.labels}>
              {content.attachmentCount > 0 && (
                <ContentLabel
                  Icon={File}
                  iconWeight="fill"
                  variant="overlay"
                  size="small"
                  label={content.attachmentCount.toString()}
                />
              )}
              {/* {content.seriesIndex !== null && (
                <ContentLabel
                  Icon={Stack}
                  iconWeight="fill"
                  variant="overlay"
                  size="small"
                  label={`${content.seriesIndex} / ${content.seriesLength}`}
                />
              )} */}
              {content.quizCompleted && (
                <ContentLabel Icon={Check} iconWeight="bold" variant="overlay" size="small" />
              )}
              <ContentLabel
                Icon={Clock}
                iconWeight="bold"
                variant="overlay"
                size="small"
                label={stringHelpers.secondsToDurationString(content.videoDuration)}
              />
            </span>
            <div className={styles.playButton}>
              <Play className={styles.icon} weight="fill" />
            </div>
          </div>
        </div>

        {/* Title */}
        <span className={styles.titleRow}>
          <h1>{content.title}</h1>
          <div className={styles.buttonGroup}>
            {user?.role === "Admin" && (
              <Button
                variant="default"
                Icon={PencilSimple}
                onClick={() => {
                  navigate(`/admin/edit/${content.id}`)
                }}
                title="Tananyag szerkesztése"
              />
            )}
            {!!content.quiz.length && (
              <Button
                variant="primary"
                onClick={() => {
                  setShouldShowQuiz(true)
                }}
                title="Teszt kitöltése"
              />
            )}
          </div>
        </span>

        {/* Labels */}
        <span className={styles.labels}>
          {content.attachmentCount > 0 && (
            <ContentLabel
              Icon={File}
              iconWeight="fill"
              label={content.attachmentCount.toString()}
            />
          )}
          {/* {content.seriesIndex !== null && (
            <ContentLabel
              Icon={Stack}
              iconWeight="fill"
              label={`${content.seriesIndex} / ${content.seriesLength}`}
            />
          )} */}
          {content.quizCompleted && <ContentLabel Icon={Check} iconWeight="bold" variant="green" />}
          <ContentLabel
            Icon={Clock}
            iconWeight="bold"
            label={stringHelpers.secondsToDurationString(content.videoDuration)}
          />
        </span>

        {/* Details */}
        <span className={styles.details}>
          <div className={styles.description}>
            <h2>Leírás</h2>
            {content.description.split("\n").map((paragraph) => (
              <p key={paragraph.substring(0, 32)}>{paragraph}</p>
            ))}
          </div>
          <div className={styles.attachments}>
            <h2>Mellékletek</h2>
            <h3>
              {content.attachmentCount > 0
                ? "Kattintson a megnyitáshoz"
                : "Nincs elérhető melléklet"}
            </h3>
            {attachmentsByType("image")}
            {attachmentsByType("document")}
            {attachmentsByType("presentation")}
          </div>
        </span>

        {/* Tag list */}
        <span className={styles.tags}>
          <h2>Címkék</h2>
          {content.tags.length === 0 && <h3>Nincs elérhető címke</h3>}
          <span className={styles.tagList}>
            {content.tags.map((t) => (
              <TagButton key={`tag-${t.id}`} tag={t} />
            ))}
          </span>
        </span>

        {/* Suggestions */}
        <span className={styles.relatedContents}>
          <h2>Kapcsolódó tananyagok</h2>
          {relatedContentList}
        </span>
      </div>
      {shouldShowQuiz && (
        <Quiz quizQuestions={content.quiz} close={() => setShouldShowQuiz(false)} />
      )}
    </>
  )
}

export default ContentPage
