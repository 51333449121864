import React, { useState } from 'react'
import { Icon as IconType, IconWeight, CaretDown, CaretUp, ArrowRight } from 'phosphor-react'
import { FormFieldError } from 'components'
import { useHandleClickOutside } from 'common/hooks'
import styles from './Dropdown.module.scss'

type OptionValue = number | null | string
type OptionLabel = string
export type DropdownOptionType<T extends OptionValue = OptionValue> = {
  value: T
  label: OptionLabel
}

interface Props {
  value: OptionValue
  options: DropdownOptionType[]
  onChange: (value: OptionValue) => void
  StartIcon?: IconType
  startIconWeight?: IconWeight
  label?: string
  placeholder?: string
  disabled?: boolean
  errorText?: string
  className?: string
}

const DropdownOption: React.FC<{ label: OptionLabel; onClick: () => void; selected?: boolean }> = ({
  label,
  onClick,
  selected,
}) => {
  return (
    <div onClick={onClick} className={[styles.option, selected && styles.selected].join(' ')}>
      {selected && <ArrowRight className={styles.icon} weight="bold" />}
      <span>{label}</span>
    </div>
  )
}

export const Dropdown: React.FC<Props> = ({
  value,
  options,
  onChange,
  placeholder,
  StartIcon,
  startIconWeight,
  label,
  disabled,
  errorText,
  className,
}) => {
  const [open, setOpen] = useState(false)
  const selection = options.find((o) => o.value === value)?.label

  const { ref: clickOutsideRef } = useHandleClickOutside<HTMLDivElement>(() => {
    if (open) setOpen(false)
  })

  const setValue = (v: OptionValue) => {
    setOpen(false)
    onChange(v)
  }

  const optionItems = options.map((o) => (
    <DropdownOption
      key={'option-' + o.value}
      label={o.label}
      onClick={() => setValue(o.value)}
      selected={o.value === value}
    />
  ))

  return (
    <div
      ref={clickOutsideRef}
      className={[styles.container, errorText && styles.error, className].join(' ')}
    >
      {label && <label>{label}</label>}
      <div
        className={[
          styles.dropdown,
          disabled && styles.disabled,
          StartIcon && styles.noPadding,
        ].join(' ')}
        onClick={disabled ? undefined : () => setOpen(!open)}
      >
        {StartIcon && (
          <span className={styles.adornment}>
            <StartIcon weight={startIconWeight || 'bold'} />
          </span>
        )}
        <span className={[styles.selection, !selection && styles.placeholder].join(' ')}>
          {selection || placeholder}
        </span>
        <span className={styles.adornment}>
          {open ? <CaretUp weight={'bold'} /> : <CaretDown weight={'bold'} />}
        </span>
      </div>
      {errorText && <FormFieldError text={errorText} />}
      <div className={[styles.optionList, open && styles.open].join(' ')}>{optionItems}</div>
    </div>
  )
}
