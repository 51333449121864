import { TextInput } from "components"
import React from "react"
import { classNames } from "common/helpers/styling"
import { ArrowSquareOut, X } from "phosphor-react"
import { AttachmentEdit, NewAttachment } from "services/content.service/content.types"
import { ActionableText } from "components/ActionableText"
import styles from "./EditableAttachmentLabel.module.scss"

type Props = (AttachmentEdit | NewAttachment) & {
  removeAttachment: () => void
  editAttachmentLabel: (title: string) => void
  cancelDeletion: () => void
  file?: File
}

const EditableAttachmentLabel = ({
  title,
  isDeleted,
  editAttachmentLabel,
  removeAttachment,
  cancelDeletion,
  file,
  url,
}: Props) => {
  const openUrl = (url?: string) => window.open(url, "_blank")
  const openFile = (file: File) => window.open(URL.createObjectURL(file), "_blank")

  return (
    <div className={styles.container}>
      <ArrowSquareOut
        className={classNames(styles.icon, isDeleted && styles.deleted)}
        size={30}
        onClick={() => (file ? openFile(file) : openUrl(url))}
      />
      <TextInput
        inputClassName={styles.input}
        className={styles.inputContainer}
        value={title}
        onChange={editAttachmentLabel}
        disabled={isDeleted}
      />
      {!isDeleted ? (
        <X className={styles.icon} size={30} key={title} onClick={removeAttachment} />
      ) : (
        <ActionableText onClick={cancelDeletion} text="Törlés visszavonása" variant="black" />
      )}
    </div>
  )
}

export default EditableAttachmentLabel
