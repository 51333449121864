import React from 'react'
import { classNames } from 'common/helpers/styling'
import { Button } from 'components/Button'
import { useHandleClickOutside } from 'common/hooks'
import { SignOut, Gear, Key, Upload } from 'phosphor-react'
import styles from './UserMenu.module.scss'

interface Props {
  open: boolean
  onClose: () => void
  onProfileSettings: () => void
  onLogout: () => void
  onStatistics?: () => void
  onCreateContent?: () => void
}

export const UserMenu: React.FC<Props> = ({
  open,
  onClose,
  onStatistics,
  onProfileSettings,
  onLogout,
  onCreateContent,
}) => {
  const { ref: clickOutsideRef } = useHandleClickOutside<HTMLDivElement>(onClose)

  return (
    <div ref={clickOutsideRef} className={classNames(styles.container, { [styles.open]: open })}>
      {onCreateContent && <Button Icon={Upload} onClick={onCreateContent} title="Tananyag feltöltése" />}
      {onStatistics && <Button Icon={Key} onClick={onStatistics} title="Statisztikák" />}
      <Button Icon={Gear} onClick={onProfileSettings} title="Beállítások" />
      <Button Icon={SignOut} iconWeight="bold" onClick={onLogout} title="Kijelentkezés" />
    </div>
  )
}
