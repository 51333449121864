// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const classNames = (...args: (string | { [key: string]: any } | null | undefined | false)[]) => {
  const classes = [] as string[]

  args.forEach((arg) => {
    if (arg) {
      if (typeof arg === 'string') {
        classes.push(arg)
      } else {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key)
          }
        })
      }
    }
  })

  return classes.join(' ')
}
