import { QuizAnswer } from 'services/content.service/content.types'
import React from 'react'
import { Checkbox } from 'components/Checkbox'
import { X as IncorrectIcon } from 'phosphor-react'
import styles from './Answers.module.scss'

interface AnswerProps {
  answer: QuizAnswer
}

export const NeutralAnswer: React.FC<AnswerProps> = ({ answer }) => {
  return <Checkbox label={answer.text} value={false} />
}

export const CorrectAnswer: React.FC<AnswerProps> = ({ answer }) => {
  return <Checkbox classes={{ checkedBox: styles.correct }} label={answer.text} value={true} />
}

export const IncorrectAnswer: React.FC<AnswerProps> = ({ answer }) => {
  return (
    <Checkbox
      classes={{ checkedBox: styles.incorrect }}
      label={answer.text}
      value={true}
      Icon={IncorrectIcon}
    />
  )
}
