import React, { useContext } from "react"
import { ContentListContext } from "App"
import { useCreatedByQueryParamAsState } from "common/hooks/useQueryParamAsState"
import { Dropdown } from "components/Dropdown"

const defaultCreatorOption = { value: null, label: "Mindenki" }

const FilterByCreator = () => {
  const { contentList } = useContext(ContentListContext)
  const [creator, setCreator] = useCreatedByQueryParamAsState()

  const creatorList = contentList?.map((content) => content.createdByEmail)

  const creatorOptions = [...new Set(creatorList)]?.map((c) => ({ value: c, label: c })) || []

  return (
    <Dropdown
      label={"Feltöltő"}
      value={creator}
      options={[defaultCreatorOption, ...creatorOptions]}
      onChange={setCreator}
    />
  )
}

export default FilterByCreator
