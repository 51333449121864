import React, { useState, useContext } from 'react'
import { PasswordInput } from 'components'
import { Button } from 'components/Button'
import authModalStyles from 'components/AuthModal/AuthModal.module.scss'
import { resetUserPassword } from 'services/user.service/User.service'
import { ApiError } from 'services/apiErrors'
import { isValidLength } from 'common/helpers/validation'
import { AuthModalContentProps } from 'components/AuthModal/AuthModal'
import { useSearchParams } from 'react-router-dom'
import { UserContext } from 'App'

type error = 'passwordTooShort' | 'requestFailed'

const validate = (data: { password: string }): error[] => {
  const errors = [] as error[]
  if (!isValidLength(data.password, { min: 8 })) {
    errors.push('passwordTooShort')
  }
  return errors
}

const errorMessages: { [key in error]: string } = {
  passwordTooShort: 'Legalább 8 karakter megadása szükséges',
  requestFailed: 'Hiba történt',
}

const PasswordResetContent: React.FC<AuthModalContentProps> = ({ closeAuthModal }) => {
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<error[]>([])
  const { setUser } = useContext(UserContext)

  const [searchParams] = useSearchParams()

  const passwordResetPayload = {
    password,
    token: searchParams.get('token'),
  }

  const resetPassword = async () => {
    const validationErrors = validate({ password })
    setErrors(validationErrors)
    if (validationErrors.length) return

    try {
      const { payload: user } = (await resetUserPassword(passwordResetPayload)).data
      setUser(user)
      closeAuthModal()
    } catch (err) {
      if (err instanceof ApiError) {
        setErrors(['requestFailed'])
        return
      }
      throw err
    }
  }

  const getError = (errorName: error) => {
    return (errors.includes(errorName) || undefined) && errorMessages[errorName]
  }

  return (
    <>
      <PasswordInput
        label="Új jelszó"
        value={password}
        onChange={setPassword}
        errorText={getError('passwordTooShort') || getError('requestFailed')}
      />

      <Button
        onClick={resetPassword}
        variant="primary"
        title="Új jelszó beállítása"
        className={authModalStyles.button}
      />
    </>
  )
}

export default PasswordResetContent
