import React, { useContext, useState } from 'react'
import { Divider } from 'components/AuthModal/Divider'
import { Loader, PasswordInput, TextInput } from 'components'
import { Dropdown } from 'components/Dropdown'
import { Button } from 'components/Button'
import authModalStyles from 'components/AuthModal/AuthModal.module.scss'
import { ActionableText } from 'components/ActionableText'
import { DropdownOptionType } from 'components/Dropdown/Dropdown'
import { Genders } from 'common/types/enums'
import {
  CreateUserPayload,
  registerAdminUser,
  registerUser,
} from 'services/user.service/User.service'
import { ApiError } from 'services/apiErrors'
import { isValidEmail, isValidLength } from 'common/helpers/validation'
import { AuthModalContentProps } from 'components/AuthModal/AuthModal'
import { Checkbox } from 'components/Checkbox'
import useAdminRegistration from 'components/AuthModal/RegistrationContent/useAdminRegistration'
import { Notice } from 'components/Notice'
import { UserContext } from 'App'
import styles from './RegistrationContent.module.scss'

const genderOptions: DropdownOptionType[] = [
  {
    value: Genders.female,
    label: 'Nő',
  },
  {
    value: Genders.male,
    label: 'Férfi',
  },
  {
    value: Genders.other,
    label: 'Egyéb',
  },
]

type error =
  | 'emailTaken'
  | 'emailInvalid'
  | 'passwordTooShort'
  | 'firstNameNotProvided'
  | 'lastNameNotProvided'
  | 'birthYearInvalid'
  | 'genderIsNull'

const validate = (data: {
  email: string
  password: string
  lastName: string
  firstName: string
  birthYear: string
  genderId: number | null
}): error[] => {
  const errors = [] as error[]
  if (!isValidEmail(data.email)) {
    errors.push('emailInvalid')
  }
  if (!isValidLength(data.password, { min: 8 })) {
    errors.push('passwordTooShort')
  }
  if (!data.lastName) {
    errors.push('lastNameNotProvided')
  }
  if (!data.firstName) {
    errors.push('firstNameNotProvided')
  }
  if (data.birthYear && !parseInt(data.birthYear, 10)) {
    errors.push('birthYearInvalid')
  }
  if (data.genderId === null) {
    errors.push('genderIsNull')
  }

  return errors
}

const errorMessages: { [key in error]: string } = {
  emailInvalid: 'A megadott email cím nem megfelelő',
  emailTaken: 'A megadott email címmel már regisztráltak',
  firstNameNotProvided: 'Kötelező mező',
  lastNameNotProvided: 'Kötelező mező',
  passwordTooShort: 'Legalább 8 karakter megadása szükséges',
  birthYearInvalid: 'Helytelen évszám',
  genderIsNull: 'Kötelező mező',
}

const AuthModalRegistrationContent: React.FC<AuthModalContentProps> = ({
  setAuthModalState,
  closeAuthModal,
}) => {
  const { setUser } = useContext(UserContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [genderId, setGenderId] = useState<null | number>(null)
  const [birthYear, setBirthYear] = useState('')
  const [errors, setErrors] = useState<error[]>([])
  const [isTeacher, setIsTeacher] = useState(false)

  const { adminCreationKey, isInvalidAdminKey, validatingKey } = useAdminRegistration(setEmail)

  const registerPayload: CreateUserPayload = {
    genderId,
    email,
    firstName,
    lastName,
    password,
    isTeacher,
    birthYear: parseInt(birthYear, 10) || null,
  }

  const register = async () => {
    const validationErrors = validate({ firstName, lastName, password, email, birthYear, genderId })
    setErrors(validationErrors)
    if (validationErrors.length) return

    try {
      if (adminCreationKey) {
        const res = await registerAdminUser({ ...registerPayload, adminCreationKey })
        setUser(res.data.payload)
        closeAuthModal()
      } else {
        await registerUser(registerPayload)
        setAuthModalState('successfulRegistration')
      }
    } catch (err) {
      if (err instanceof ApiError && err.message === 'email already taken') {
        setErrors(['emailTaken'])
        return
      }
      throw err
    }
  }

  const getError = (errorName: error) => {
    return (errors.includes(errorName) || undefined) && errorMessages[errorName]
  }

  if (validatingKey) {
    return <Loader size={'large'} className={styles.loader} />
  }

  return (
    <>
      <TextInput
        label="Email cím"
        value={email}
        onChange={setEmail}
        errorText={getError('emailInvalid') || getError('emailTaken')}
        disabled={!!adminCreationKey}
      />
      <PasswordInput
        label="Jelszó"
        value={password}
        onChange={setPassword}
        errorText={getError('passwordTooShort')}
      />
      <TextInput
        label="Vezetéknév"
        value={lastName}
        onChange={setLastName}
        errorText={getError('lastNameNotProvided')}
      />
      <TextInput
        label="Keresztnév"
        value={firstName}
        onChange={setFirstName}
        errorText={getError('firstNameNotProvided')}
      />

      <div className={styles.flexWrapper}>
        <TextInput
          label="Születési év (opc.)"
          value={birthYear}
          onChange={setBirthYear}
          errorText={getError('birthYearInvalid')}
        />
        <Dropdown
          label="Nem"
          value={genderId}
          options={genderOptions}
          onChange={(value) => setGenderId(typeof value === 'number' ? value : Genders.other)}
          placeholder="Válasszon"
          errorText={getError('genderIsNull')}
        />
      </div>

      {!adminCreationKey && (
        <Checkbox
          label="Tanári fiókot szeretnék regisztrálni"
          value={isTeacher}
          toggle={() => setIsTeacher(!isTeacher)}
          classes={{ container: styles.checkbox }}
        />
      )}

      {isInvalidAdminKey && (
        <Notice level={'error'} text={'Ez az admin regisztrációs link nem érvényes'} />
      )}

      <Button
        onClick={register}
        variant="primary"
        title={adminCreationKey ? 'Admin regisztráció' : 'Regisztráció'}
        className={authModalStyles.button}
      />

      <Divider marginTop={10} marginBottom={10} />
      <ActionableText
        className={authModalStyles.actionableText}
        onClick={() => setAuthModalState('login')}
        text="Vissza a bejelentkezéshez"
      />
    </>
  )
}

export default AuthModalRegistrationContent
