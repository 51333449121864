import React from "react"
import { isEqual } from "lodash"
import { postAttachments, updateAttachments } from "services/content.service/content.service"
import WhiteGreyOneColumnTable from "components/Table/WhiteGreyOneColumnTable"
import { Attachment, AttachmentEdit, NewAttachment } from "services/content.service/content.types"
import { Button } from "components"
import { Plus } from "phosphor-react"
import EditableAttachmentLabel from "./EditableAttachmentLabel"
import styles from "./AttachmentEditor.module.scss"
import { ChangeSet } from "../EditContentPage"

interface Props {
  contentId: number
  attachmentsInitialValue: Attachment[]
  addToChangeSet: (key: keyof ChangeSet, value: () => Promise<unknown>) => void
  removeFromChangeSet: (key: keyof ChangeSet) => void
}

const AttachmentEditor = ({
  attachmentsInitialValue,
  addToChangeSet,
  removeFromChangeSet,
  contentId,
}: Props) => {
  const [attachments, setAttachments] =
    React.useState<(AttachmentEdit | NewAttachment)[]>(attachmentsInitialValue)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const updateChanges = (atts: (AttachmentEdit | NewAttachment)[]) => {
    if (isEqual(attachmentsInitialValue, atts)) {
      removeFromChangeSet("attachments")
    } else {
      addToChangeSet("attachments", async (targetId?: number) => {
        const id = targetId ?? contentId // target id is only used when creating a new content
        const attachmentsToUpdate = atts.filter(
          (a) => !(a as NewAttachment).isNew,
        ) as AttachmentEdit[]
        const attachmentsToCreate = atts.filter((a) => !a.url) as NewAttachment[]
        const promises: Promise<unknown>[] = []
        if (attachmentsToUpdate.length) {
          promises.push(updateAttachments(id, attachmentsToUpdate))
        }
        if (attachmentsToCreate.length) {
          const data = new FormData()
          attachmentsToCreate.forEach((a) => {
            data.append(a.title, a.file)
          })
          promises.push(postAttachments(id, data))
        }
        await Promise.all(promises)
      })
    }
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const attachmentsCopy = attachments.slice()
    Array.from(e.target.files).forEach((file) => {
      attachmentsCopy.push({
        title: file.name,
        file,
        isNew: true,
      })
    })
    setAttachments(attachmentsCopy)
    updateChanges(attachmentsCopy)
  }

  const removeAttachment = (index: number) => {
    const attachmentsCopy = attachments.slice()
    if (!attachmentsCopy[index].url) {
      attachmentsCopy.splice(index, 1)
    } else {
      attachmentsCopy[index].isDeleted = true
    }
    setAttachments(attachmentsCopy)
    updateChanges(attachmentsCopy)
  }

  const editAttachmentLabel = (index: number, title: string) => {
    const attachmentsCopy = attachments.slice()
    attachmentsCopy[index].title = title
    attachmentsCopy[index].isChanged = true
    setAttachments(attachmentsCopy)
    updateChanges(attachmentsCopy)
  }

  const cancelDeletion = (index: number) => {
    if (!attachments[index].isDeleted) return
    const attachmentsCopy = attachments.slice()
    delete attachmentsCopy[index].isDeleted
    setAttachments(attachmentsCopy)
    updateChanges(attachmentsCopy)
  }

  return (
    <div className={styles.container}>
      <h3>Mellékletek</h3>
      <div className={styles.attachmentsContainer}>
        {!attachments.length ? (
          <div className={styles.placeholder}>
            A tananyaghoz egyetlen melléklet sem tartozik. Új melléklet felvételéhez kattintson az
            &apos;Új melléklet hozzáadása&apos; gombra!
          </div>
        ) : (
          <WhiteGreyOneColumnTable>
            {attachments.map((a, i) => (
              <EditableAttachmentLabel
                key={i}
                editAttachmentLabel={(val) => editAttachmentLabel(i, val)}
                removeAttachment={() => removeAttachment(i)}
                cancelDeletion={() => cancelDeletion(i)}
                {...a}
              />
            ))}
          </WhiteGreyOneColumnTable>
        )}
        <Button
          className={styles.addAttachmentButton}
          variant="default"
          onClick={() => inputRef.current?.click()}
          title="Új melléklet hozzáadása"
          Icon={Plus}
          iconWeight="bold"
        />
        <input
          type="file"
          className={styles.fileInput}
          ref={inputRef}
          multiple
          accept=".pdf, .ppt, image/jpeg, iamge/jpg, image/png, image/webp"
          onChange={handleFileUpload}
        />
      </div>
    </div>
  )
}

export default AttachmentEditor
