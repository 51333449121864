import React, { useState } from "react"
import { Button, TextArea } from "components"
import { Notice } from "components/Notice"
import { QuizAnswerEdit, QuizQuestionEdit } from "services/content.service/content.types"
import WhiteGreyOneColumnTable from "components/Table/WhiteGreyOneColumnTable"
import EditableCheckbox from "components/Checkbox/EditableCheckbox"
import { classNames } from "common/helpers/styling"
import { ArrowUUpLeft, Trash, Plus, X } from "phosphor-react"
import styles from "./SingleQuizQuestionEditor.module.scss"

interface Props {
  question: QuizQuestionEdit
  onChange: (value: QuizQuestionEdit) => void
  onRemoveNewlyAddedQuizQuestion: (id: number) => void
}

const SingleQuizQuestionEditor = ({
  question,
  onChange,
  onRemoveNewlyAddedQuizQuestion,
}: Props) => {
  const [error, setError] = useState<string | undefined>(undefined)
  const [shouldShowNotice, setShouldShowNotice] = useState(false)
  const errorTimeoutRef = React.useRef<number | undefined>(undefined)

  const setErrorWithTimeout = (message: string) => {
    if (errorTimeoutRef.current) {
      clearInterval(errorTimeoutRef.current)
    }
    setError(message)
    setShouldShowNotice(true)
    errorTimeoutRef.current = window.setTimeout(() => {
      setShouldShowNotice(false)
    }, 3000)
  }

  const onTitleChange = (value: string) => {
    onChange({
      ...question,
      title: value,
    })
  }

  const onCorrectAnswerChange = (id: number) => {
    onChange({
      ...question,
      correctAnswerId: id,
    })
  }

  const onRemoveAnswer = (id: number) => {
    if (id === question.correctAnswerId) {
      setErrorWithTimeout("A helyes válasz nem törölhető!")
      return
    }
    const answersCopy = question.answers.slice()
    const index = answersCopy.findIndex((answer) => answer.id === id)
    if (answersCopy[index].isNew) {
      answersCopy.splice(index, 1)
    } else {
      answersCopy[index].isDeleted = true
    }
    onChange({
      ...question,
      answers: answersCopy,
    })
  }

  const onAddAnswer = () => {
    const answersCopy = question.answers.slice()
    const newAnswerId = answersCopy.reduce((acc, answer) => Math.max(acc, answer.id), 0) + 1 // this is not the real id, the database will generate one
    const newAnswer: QuizAnswerEdit = {
      id: newAnswerId,
      text: "",
      isNew: true,
    }
    answersCopy.push(newAnswer)
    onChange({
      ...question,
      answers: answersCopy,
    })
  }

  const onAnswerChange = (value: string, id: number) => {
    const answersCopy = question.answers.slice()
    const index = answersCopy.findIndex((answer) => answer.id === id)
    answersCopy[index].text = value
    onChange({
      ...question,
      answers: answersCopy,
    })
  }

  const onDeleteQuestion = () => {
    const questionCopy = { ...question }
    if (questionCopy.isNew) {
      onRemoveNewlyAddedQuizQuestion(questionCopy.id)
      return
    }
    questionCopy.isDeleted = true
    onChange(questionCopy)
  }

  const onCancelDeleteQuestion = () => {
    const questionCopy = { ...question }
    delete questionCopy.isDeleted
    onChange(questionCopy)
  }

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.header}>
        <TextArea
          minRows={1}
          maxRows={4}
          className={styles.titleInput}
          value={question.title}
          onChange={onTitleChange}
          disabled={question.isDeleted}
          placeholder="Kattintson ide a kérdés szerkesztéséhez..."
        />
        <Button
          variant={question.isDeleted ? "default" : "destructive"}
          onClick={() => (question.isDeleted ? onCancelDeleteQuestion() : onDeleteQuestion())}
          Icon={question.isDeleted ? ArrowUUpLeft : Trash}
          title={question.isDeleted ? "Törlés visszavonása" : "Törlés"}
        />
      </div>
      <WhiteGreyOneColumnTable>
        {question.answers
          .filter((a) => !a.isDeleted)
          .map((answer, index) => (
            <>
              <EditableCheckbox
                setLabel={(val) => onAnswerChange(val, answer.id)}
                label={answer.text}
                value={answer.id === question.correctAnswerId}
                toggle={() => onCorrectAnswerChange(answer.id)}
                disabled={question.isDeleted}
                classes={{
                  container: styles.editorContainer,
                  inputContainer: styles.inputContainer,
                }}
                placeholder="Kattintson ide a válasz szerkesztéséhez..."
              />
              <X
                className={classNames(styles.icon, {
                  [styles.disabled]: question.isDeleted || question.correctAnswerId === answer.id,
                })}
                size={30}
                onClick={() => !question.isDeleted && onRemoveAnswer(answer.id)}
                opacity={question.isDeleted ? 0.5 : 1}
                key={index}
              />
            </>
          ))}
      </WhiteGreyOneColumnTable>
      {error && (
        <Notice
          className={classNames(styles.notice, shouldShowNotice && styles.visible)}
          level="error"
          text={error || ""}
        />
      )}
      <Button
        variant="default"
        onClick={onAddAnswer}
        title="Új válasz hozzáadása"
        Icon={Plus}
        iconWeight="bold"
        disabled={question.isDeleted}
      />
    </div>
  )
}

export default SingleQuizQuestionEditor
