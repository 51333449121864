import React, { useContext } from 'react'
import { DialogContext } from 'App'
import { X } from 'phosphor-react';
import { Button } from 'components/Button';
import styles from './Dialog.module.scss'

const Dialog: React.FC = () => {
  const { options, setOptions } = useContext(DialogContext);
  if (options === null) {
    return null
  }

  const dismiss = () => setOptions(null)

  const performAction = (action?: () => void) => {
    action?.()
    dismiss()
  }


  return (
    <div
      className={styles.backdrop}
      onClick={options.dismissable ? dismiss : undefined}
    >
      <div
        className={styles.card}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>
          <h2>{options.title}</h2>
          {!!options.dismissable && (
            <X
              className={styles.closeButton}
              onClick={dismiss}
            />
          )}
        </div>
        <p className={styles.message}>{options.message}</p>
        <div className={styles.actionGroup}>
          {options.actions.map((action) => (
            <Button
              key={`dialog-action-${action.title}`}
              title={action.title}
              variant={action.variant}
              Icon={action.icon}
              onClick={() => performAction(action.action)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dialog
