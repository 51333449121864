import { AdminData } from 'services/admin.service/admin.service'
import React from 'react'
import { ActionableText } from 'components/ActionableText'

const createSuperAdminTableRow = (
  admin: AdminData,
  setEmailBeingRevoked: (email: string | null) => void,
) => [
  `${admin.lastName} ${admin.firstName}`,
  admin.email,
  <ActionableText
    key={admin.email}
    onClick={() => setEmailBeingRevoked(admin.email)}
    text={'Adminisztrátori jogok megvonása'}
  />,
]
const createTableRow = (admin: AdminData) => [admin.email]

export const createTableData = (
  admins: AdminData[],
  isSuperAdmin: boolean | undefined,
  setEmailBeingRevoked: (email: null | string) => void,
) => {
  const titleRowData = isSuperAdmin ? ['név', 'email', ''] : ['név', 'email']

  const adminTableData = admins.flatMap((admin) =>
    isSuperAdmin ? createSuperAdminTableRow(admin, setEmailBeingRevoked) : createTableRow(admin),
  )
  return [...titleRowData, ...adminTableData]
}
