import React from "react"
import { classNames } from "common/helpers/styling"
import styles from "./ActionableText.module.scss"

interface ActionableTextProps {
  onClick: () => void
  text: string
  variant?: "blue" | "primary" | "black"
  className?: string
}

const ActionableText: React.FC<ActionableTextProps> = ({
  variant = "blue",
  text,
  onClick,
  className,
}) => {
  return (
    <p
      onClick={onClick}
      className={classNames(styles.actionableText, className, {
        [styles.blue]: variant === "blue",
        [styles.primary]: variant === "primary",
        [styles.grey]: variant === "black",
      })}
    >
      {text}
    </p>
  )
}

export default ActionableText
