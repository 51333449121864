import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { GreyOverlay } from 'components/GreyOverlay'
import { Button, Card } from 'components'
import { ApiError } from 'services/apiErrors'
import { Notice } from 'components/Notice'
import styles from './RevokeAdminRightPopup.module.scss'

interface Props {
  close: () => void
  commit: () => Promise<void>
  email: string
}

const RevokeAdminRightPopup: React.FC<Props> = ({ close, commit, email }) => {
  const [error, setError] = useState(false)

  const handleCommit = async () => {
    try {
      await commit()
      close()
    } catch (err) {
      if (err instanceof ApiError && err.message === 'invalid revoke request') {
        setError(true)
        return
      }
      throw err
    }
  }

  return ReactDOM.createPortal(
    <GreyOverlay>
      <Card className={styles.card} header={{ title: 'Admini jogok megvonása', close }}>
        <p className={styles.text}>
          Biztosan megvonja a <strong>{email}</strong> email címhez tartozó fiók adminisztrátori
          jogait?
        </p>
        {error && (
          <Notice
            className={styles.notice}
            level={'error'}
            text={'A jogok megvonása sikertelen volt.'}
          />
        )}
        <div className={styles.buttonContainer}>
          <Button onClick={close} title={'Mégsem'} />
          <Button
            disabled={error}
            onClick={handleCommit}
            title={'Jogok megvonása'}
            variant={'destructive'}
          />
        </div>
      </Card>
    </GreyOverlay>,
    document.body,
  )
}

export default RevokeAdminRightPopup
