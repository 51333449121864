import { Notice } from 'components/Notice'
import styles from 'components/AuthModal/LoginContent/LoginContent.module.scss'
import React from 'react'

export const FailedEmailActivationNotice = () => {
  return (
    <Notice
      className={styles.notice}
      text="Az aktiváló link lejárt vagy a hozzá tartozó fiókot már aktiválták."
      level="warning"
    />
  )
}

export const SuccessfulEmailActivationNotice = () => {
  return (
    <Notice
      className={styles.notice}
      text="Sikeresen aktiválta fiókját! Jelentkezzen be a folytatáshoz."
      level="success"
    />
  )
}

export const InvalidCredentialsNotice = () => {
  return (
    <Notice className={styles.notice} text="Helytelen felhasználónév vagy jelszó." level="error" />
  )
}
