import { DialogContext } from 'App'
import { DialogOptions } from 'common/types/contexts'
import { useContext } from 'react'

export function useConfirmationDialog(
  options: Partial<DialogOptions> & Pick<DialogOptions, 'actions'>
) {
  const { setOptions } = useContext(DialogContext)

  const confirm = () => {
    setOptions({
      dismissable: options?.dismissable || true,
      title: options?.title || 'Biztos benne?',
      message: options?.message || 'Az elvégezni kívánt művelet nem visszavonható. Biztos benne?',
      actions: options.actions
    })
  }

  return confirm
}

export function useErrorModal() {
  const { setOptions } = useContext(DialogContext)

  const errorModal = (message: string) => {
    setOptions({
      dismissable: true,
      title: 'Hiba',
      message,
      actions: [
        { title: 'Ok' }
      ]
    })
  }

  return errorModal
}
