import React from 'react'
import { Outlet } from 'react-router-dom'
import { AuthModal } from 'components/AuthModal'
import { Header } from './Header'
import { Footer } from './Footer'
import styles from './PageBase.module.scss'

export const PageBase: React.FC = () => {
  return (
    <div className={styles.container} id="eda-content-display">
      <Header />
      <div className={styles.content}>
        <Outlet />
        <Footer />
      </div>
      <AuthModal />
    </div>
  )
}
