import React from 'react'
import {
  registrationTypesTuple,
  StatisticsResponse,
} from 'services/statistics.service/statistics.types'
import OpenableTable from 'pages/Admin/AdminStatisticsPage/AdminsTable/OpenableTable/OpenableTable'

interface RegistrationStatisticsTableProps {
  data: StatisticsResponse
}

const tableHeader = [
  'Csoport',
  'Összesen',
  'Elmúlt hónapban',
  '0-25 év',
  '25-50 év',
  '50+ év',
  'Férfi',
  'Nő',
] as const

const labels: {
  [key in typeof tableHeader[number]]: typeof registrationTypesTuple[number] | undefined
} = {
  Csoport: undefined,
  Összesen: 'sum',
  'Elmúlt hónapban': 'last_month',
  '0-25 év': 'under_25',
  '25-50 év': 'between_25_50',
  '50+ év': 'over_50',
  Férfi: 'male',
  Nő: 'female',
}

const rowTitles: { [key in keyof StatisticsResponse['registration']]: string } = {
  generic: 'Általános regisztráció',
  teacher: 'Tanári regisztráció',
}

const convertData = (data: StatisticsResponse) => {
  const results: (number | string)[] = [...tableHeader]
  Object.entries(rowTitles).forEach(([titleKey, title]) => {
    const registrationResults = data.registration[titleKey as keyof typeof rowTitles]
    results.push(title) // 1st column is the title
    tableHeader.forEach((category) => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const key = (labels as any)[category]
      if (key !== undefined) results.push((registrationResults as any)[key] || 0)
      /* eslint-enable */
    })
  })

  return results
}

const RegistrationStatisticsTable: React.FC<RegistrationStatisticsTableProps> = ({ data }) => {
  return (
    <OpenableTable data={convertData(data)} numOfColumns={8} title={'Regisztrált felhasználók'} />
  )
}

export default RegistrationStatisticsTable
