import React, { useEffect, useState } from 'react'
import { ContentTag, CreatableContentTag as Tag } from 'services/content.service/content.types'
import { getAllTags } from 'services/content.service/content.service'
import { Plus } from 'phosphor-react'
import { classNames } from 'common/helpers/styling'
import styles from './styles.module.scss'
import { DeletableTag } from './DeletableTag'
import { TagSelector } from './TagSelector'

interface Props {
  currentTags: ContentTag[]
  onChange: (tags: Tag[]) => void
}

export const TagEditor = ({ currentTags, onChange }: Props) => {
  const [tagSelectorOpen, setTagSelectorOpen] = useState(false)
  const [allTags, setAllTags] = useState<ContentTag[]>([])
  const [tagList, setTagList] = useState<Tag[]>(currentTags)

  useEffect(() => {
    const loadTags = async () => {
      setAllTags((await getAllTags()).data.payload)
    }
    loadTags()
  }, [])

  const addTag = (tag: Tag) => {
    const newTagList = [...tagList, tag]
    setTagList(newTagList)
    onChange(newTagList)
  }

  const removeTag = (tag: Tag) => {
    const newTagList = tagList.filter((t) => t.name !== tag.name)
    setTagList(newTagList)
    onChange(newTagList)
  }

  const handleTagSelection = (tag?: Tag) => {
    if(tag) {
      addTag(tag)
    }
    setTagSelectorOpen(false)
  }

  return (
    <div className={styles.container}>
      {tagList.map((tag) => (
        <DeletableTag
          key={`tag-${tag.name}`}
          name={tag.name}
          onDelete={() => removeTag(tag)}
        />
      ))}
      {tagSelectorOpen && (
        <TagSelector
          allTags={allTags}
          focusOnRender={true}
          options={allTags.filter((tag) =>
            !tagList.map((currentTag) => currentTag.id).includes(tag.id)
          )}
          onFinished={handleTagSelection}
        />
      )}
      {!tagSelectorOpen && (
        <div
          className={classNames(styles.tag, styles.addNew)}
          onClick={() => {
            if (!tagSelectorOpen) {
              setTagSelectorOpen(true)
            }
          }}
        >
          <Plus />
          <h5>
            Címke hozzáadása
          </h5>
        </div>
      )}

    </div>
  );
};
