import React from 'react'
import { X as Close } from 'phosphor-react'
import styles from './TopSection.module.scss'

interface TopSectionProps {
  title: string
  closeAuthModal: () => void
}

const TopSection: React.FC<TopSectionProps> = ({ title, closeAuthModal }) => {
  return (
    <div className={styles.authModalTopSection}>
      <h2 className={styles.title}>{title}</h2>
      <Close onClick={closeAuthModal} className={styles.closeButton} size={25} weight="bold" />
    </div>
  )
}

export default TopSection
