import React, { useState } from "react"
import { Button } from "components"
import { isEqual } from "lodash"
import { Plus } from "phosphor-react"
import { updateContentQuiz } from "services/content.service/content.service"
import { QuizQuestion, QuizQuestionEdit } from "services/content.service/content.types"
import SingleQuizQuestionEditor from "./SingleQuizQuestionEditor/SingleQuizQuestionEditor"
import styles from "./QuizEditor.module.scss"
import { ChangeSet } from "../EditContentPage"
import { SectionTitle } from "../SectionTitle"

interface Props {
  contentId: number
  currentQuiz: QuizQuestionEdit[]
  addToChangeSet: (key: keyof ChangeSet, value: () => Promise<unknown>) => void
  removeFromChangeSet: (key: keyof ChangeSet) => void
  addToValidations: (key: keyof ChangeSet, value: () => string | null) => void
}

const validateQuiz = (quizQuestions: QuizQuestionEdit[]) => {
  for (const quizQuestion of quizQuestions) {
    if (!quizQuestion.title) {
      return "Minden kérdésnek rendelkeznie kell címmel!"
    }
    if (quizQuestion.answers.length < 2) {
      return "Minden kérdésnek legalább két választ kell tartalmaznia!"
    }
    if (!quizQuestion.correctAnswerId) {
      return "Minden kérdésnek rendelkeznie kell helyes válasszal!"
    }
    for (const answer of quizQuestion.answers) {
      if (!answer.text) {
        return "Minden válasznak rendelkeznie kell szöveggel!"
      }
    }
  }
  return null
}

const QuizEditor = ({
  contentId,
  currentQuiz,
  addToChangeSet,
  removeFromChangeSet,
  addToValidations,
}: Props) => {
  const [updatedQuiz, setUpdatedQuiz] = useState<QuizQuestionEdit[]>(currentQuiz)

  const onQuizQuestionChange = (value: QuizQuestion, id: number) => {
    const updatedQuizCopy = updatedQuiz.slice()
    const index = updatedQuizCopy.findIndex((quizQuestion) => quizQuestion.id === id)
    updatedQuizCopy[index] = value
    setUpdatedQuiz(updatedQuizCopy)
    if (isEqual(currentQuiz, updatedQuizCopy)) {
      removeFromChangeSet("quiz")
    } else {
      addToChangeSet("quiz", (targetId?: number) =>
        updateContentQuiz(targetId || contentId, updatedQuizCopy),
      )
      addToValidations("quiz", () => validateQuiz(updatedQuizCopy))
    }
  }

  const onRemoveNewlyAddedQuizQuestion = (id: number) => {
    const updatedQuizCopy = updatedQuiz.slice()
    const index = updatedQuizCopy.findIndex((quizQuestion) => quizQuestion.id === id)
    updatedQuizCopy.splice(index, 1)
    setUpdatedQuiz(updatedQuizCopy)
    if (isEqual(currentQuiz, updatedQuizCopy)) {
      removeFromChangeSet("quiz")
    } else {
      addToChangeSet("quiz", (targetId?: number) =>
        updateContentQuiz(targetId || contentId, updatedQuizCopy),
      )
    }
  }

  const onAddQuizQuestion = () => {
    const updatedQuizCopy = updatedQuiz.slice()
    const nextQuestionId = updatedQuizCopy.reduce((acc, curr) => Math.max(acc, curr.id), 0) + 1 // dummy id
    updatedQuizCopy.push({
      id: nextQuestionId,
      title: "",
      answers: [
        {
          id: 1,
          text: "",
          isNew: true,
        },
        {
          id: 2,
          text: "",
          isNew: true,
        },
      ],
      correctAnswerId: 1,
      isNew: true,
    })
    setUpdatedQuiz(updatedQuizCopy)
    addToChangeSet("quiz", (targetId?: number) =>
      updateContentQuiz(targetId || contentId, updatedQuizCopy),
    )
  }

  return (
    <>
      <SectionTitle>Kvíz kérdések</SectionTitle>
      <div className={styles.container}>
        {updatedQuiz.map((quizQuestion, index) => {
          return (
            <SingleQuizQuestionEditor
              key={index}
              question={quizQuestion}
              onChange={(value) => onQuizQuestionChange(value, quizQuestion.id)}
              onRemoveNewlyAddedQuizQuestion={onRemoveNewlyAddedQuizQuestion}
            />
          )
        })}
        {updatedQuiz.length === 0 && (
          <div className={styles.placeholder}>
            A tananyaghoz egyetlen kvíz kérdés sem tartozik. Új kérdés felvételéhez kattintson az
            &apos;Új kérdés hozzáadása&apos; gombra!
          </div>
        )}
        <Button
          Icon={Plus}
          iconWeight="bold"
          onClick={onAddQuizQuestion}
          title="Új kérdés hozzáadása"
          className={styles.button}
        />
      </div>
    </>
  )
}

export default QuizEditor
