import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Play, File, Check, Clock } from 'phosphor-react'
import { ContentLabel } from 'components'
import { stringHelpers } from 'common/helpers'
import { ContentSummary } from 'services/content.service/content.types'
import { classNames } from 'common/helpers/styling'
import styles from './ContentCard.module.scss'

interface Props {
  content: ContentSummary
  className?: string
  onSelect?: () => void
}

export const ContentCard: React.FC<Props> = ({ content, className, onSelect }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    onSelect?.()
    navigate(`/content/${content.id}`)
  }

  return (
    <div className={classNames(styles.container, className)} onClick={handleClick}>
      <div className={styles.thumbnail}>
        <img draggable={false} src={content.thumbnailUrl} />
        <div className={styles.shadow} />
        <div className={styles.playButton}>
          <Play className={styles.icon} weight="fill" />
        </div>
      </div>
      <h1 className={styles.title}>{stringHelpers.ellipsize(content.title, 24)}</h1>
      <p className={styles.description}>{stringHelpers.ellipsize(content.description, 80)}</p>
      <div className={styles.details}>
        <span className={styles.block}>
          {content.attachmentCount > 0 && (
            <ContentLabel
              Icon={File}
              iconWeight="fill"
              label={content.attachmentCount.toString()}
            />
          )}
          {/* {content.seriesIndex !== null && (
            <ContentLabel
              Icon={Stack}
              iconWeight="fill"
              label={`${content.seriesIndex} / ${content.seriesLength}`}
            />
          )} */}
        </span>
        <span className={styles.block}>
          {content.quizCompleted && <ContentLabel Icon={Check} iconWeight="bold" variant="green" />}
          <ContentLabel
            variant="primary"
            Icon={Clock}
            iconWeight="bold"
            label={stringHelpers.secondsToDurationString(content.videoDuration)}
          />
        </span>
      </div>
    </div>
  )
}
