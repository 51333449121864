export enum Genders {
  female = 1,
  male = 2,
  other = 3,
}

export enum AttachmentTypes {
  image,
  document,
  presentation,
}
