import React, { useContext } from "react"
import ReactDOM from "react-dom"
import { UserContext } from "App"
import { Card } from "components/Card"
import { Dropdown } from "components/Dropdown"
import { OrderQueryParam, useOrderQueryParamAsState } from "common/hooks/useQueryParamAsState"
import { DropdownOptionType } from "components/Dropdown/Dropdown"
import { ContentTag } from "services/content.service/content.types"
import { TagSelector } from "components/PageBase/Header/FilterModal/TagSelector"
import { classNames } from "common/helpers/styling"
import styles from "./FilterModal.module.scss"
import UploadedAfterFilter from "./UploadedAfterFilter/UploadedAfterFilter"
import FilterByCreator from "./FilterByCreator/FilterByCreator"

const orderingOptions: DropdownOptionType<OrderQueryParam>[] = [
  { value: 'date_asc', label: 'Legújabb előre' },
  { value: 'date_desc', label: 'Legrégebbi előre' },
  { value: null, label: 'Relevancia' },
]

interface FilterModalProps {
  open: boolean
  availableTags: ContentTag[]
}

const FilterModal: React.FC<FilterModalProps> = ({ open, availableTags }) => {
  const [order, setOrder] = useOrderQueryParamAsState()

  const { user } = useContext(UserContext)

  return ReactDOM.createPortal(
    <Card className={classNames(styles.card, { [styles.open]: open })}>
      <Dropdown label={'Rendezés'} value={order} options={orderingOptions} onChange={setOrder} />
      <UploadedAfterFilter />
      <TagSelector availableTags={availableTags} />
      {user?.role === "Admin" && <FilterByCreator />}
    </Card>,
    document.body,
  )
}

export default FilterModal
