import React, { useContext, useEffect } from 'react'
import { UserContext } from 'App'
import { Outlet, useNavigate } from 'react-router-dom'
import { ArrowLeft } from 'phosphor-react'
import styles from './AdminPageBase.module.scss'

const AdminPageBase: React.FC = () => {
  const { user, userLoaded } = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (userLoaded) {
      if (user && user.role !== 'Admin') {
        navigate('/home/auth/login', { replace: true })
      }
    }
  }, [user, userLoaded, navigate])

  const handleBack = () => {
    navigate('/home')
  }

  return (
    <div className={styles.container}>
      <span>
        <ArrowLeft className={styles.backButton} weight="bold" onClick={handleBack} />
        <img draggable={false} src={'/logo/logo-admin.svg'} alt={''} className={styles.logo} />
      </span>
      <Outlet />
    </div>
  )
}

export default AdminPageBase
