import React, { useState } from 'react'
import { Divider } from 'components/AuthModal/Divider'
import { TextInput } from 'components'
import { Button } from 'components/Button'
import { ActionableText } from 'components/ActionableText'
import { forgottenUserPassword } from 'services/user.service/User.service'
import { ApiError } from 'services/apiErrors'
import { isValidEmail } from 'common/helpers/validation'
import { AuthModalContentProps } from 'components/AuthModal/AuthModal'
import authModalStyles from 'components/AuthModal/AuthModal.module.scss'
import { ErrorNotice, PasswordResetEmailSentNotice } from './ForgottenPasswordContentNotices'

type error = 'emailEmpty' | 'emailInvalid' | 'invalidCredentials'

const validate = (data: { email: string }): error[] => {
  const errors = [] as error[]
  if (data.email === '') {
    errors.push('emailEmpty')
  } else if (!isValidEmail(data.email)) {
    errors.push('emailInvalid')
  }
  return errors
}

const errorMessages: { [key in error]: string } = {
  emailEmpty: 'Kötelező mező',
  emailInvalid: 'A megadott email cím nem megfelelő',
  invalidCredentials: 'Hibás email cím vagy jelszó',
}

const ForgottenPasswordContent: React.FC<AuthModalContentProps> = ({ setAuthModalState }) => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState<error[]>([])
  const [success, setSuccess] = useState<boolean | null>(null)

  const forgottenPasswordPayload = {
    email: '',
  }

  const login = async () => {
    const validationErrors = validate({ email })
    setErrors(validationErrors)
    if (validationErrors.length) return
    forgottenPasswordPayload.email = email
    try {
      await forgottenUserPassword(forgottenPasswordPayload)
      setSuccess(true)
    } catch (err) {
      if (err instanceof ApiError) {
        setSuccess(false)
        return
      }
      throw err
    }
  }

  const getError = (errorName: error) => {
    return (errors.includes(errorName) || undefined) && errorMessages[errorName]
  }

  return (
    <>
      <TextInput
        label="Email cím"
        value={email}
        onChange={setEmail}
        errorText={getError('emailEmpty') || getError('emailInvalid')}
      />

      {success && <PasswordResetEmailSentNotice />}
      {success === false && <ErrorNotice />}

      {success !== true && (
        <Button
          onClick={login}
          variant="primary"
          title="Új jelszó kérése"
          className={authModalStyles.button}
        />
      )}

      <Divider marginTop={20} marginBottom={20} />
      <ActionableText
        className={authModalStyles.actionableText}
        onClick={() => setAuthModalState('login')}
        text="Vissza a bejelentkezéshez"
      />
    </>
  )
}

export default ForgottenPasswordContent
