import React, { useEffect, useState } from 'react'
import { Button, Dropdown, PasswordInput, TextInput } from 'components'
import { Info } from 'phosphor-react'
import { DropdownOptionType } from 'components/Dropdown/Dropdown'
import { Genders } from 'common/types/enums'
import { updateUser, User } from 'services/user.service/User.service'
import { isValidLength } from 'common/helpers/validation'
import styles from './ProfileForm.module.scss'

type FieldError =
  | 'firstNameEmpty'
  | 'lastNameEmpty'
  | 'birthYearInvalid'
  | 'currentPasswordEmpty'
  | 'newPasswordTooShort'
  | 'currentPasswordInvalid'

const validate = (data: {
  lastName: string
  firstName: string
  birthYear: number | null
  currentPassword: string
  newPassword: string
}): FieldError[] => {
  const errors = [] as FieldError[]
  if (data.lastName === '') errors.push('lastNameEmpty')
  if (data.firstName === '') errors.push('firstNameEmpty')
  if (
    data.birthYear !== null &&
    (data.birthYear < 1900 || data.birthYear > new Date().getFullYear())
  ) {
    errors.push('birthYearInvalid')
  }
  if (data.newPassword !== '' && !isValidLength(data.newPassword, { min: 8 })) {
    errors.push('newPasswordTooShort')
  }
  if (data.newPassword !== '' && data.currentPassword === '') {
    errors.push('currentPasswordEmpty')
  }
  return errors
}

const errorMessages: { [key in FieldError]: string } = {
  firstNameEmpty: 'Kötelező mező',
  lastNameEmpty: 'Kötelező mező',
  currentPasswordEmpty: 'Kötelező mező',
  birthYearInvalid: 'Hibás születési év',
  newPasswordTooShort: 'Legalább 8 karakter megadása szükséges',
  currentPasswordInvalid: 'Helytelen jelszó',
}

interface Props {
  user: User
  setUser: (user: User) => void
}

const SectionTitle = ({ children }: { children?: React.ReactNode }) => (
  <div className={styles.sectionTitle}>
    <h3>{children}</h3>
    <span />
  </div>
)

const ProfileForm: React.FC<Props> = ({ user, setUser }) => {
  const [lastName, setLastName] = useState(user.lastName)
  const [firstName, setFirstName] = useState(user.firstName || '')
  const [genderID, setGenderID] = useState<number>(user.genderID)
  const [birthYear, setBirthYear] = useState<number | null>(user.birthYear)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState<FieldError[]>([])
  const [saved, setSaved] = useState(false)

  const genderOptions: DropdownOptionType[] = [
    {
      value: Genders.female,
      label: 'Nő',
    },
    {
      value: Genders.male,
      label: 'Férfi',
    },
    {
      value: Genders.other,
      label: 'Inkább nem adom meg',
    },
  ]

  useEffect(() => {
    if (
      lastName === user.lastName &&
      firstName === user.firstName &&
      genderID === user.genderID &&
      birthYear === user.birthYear &&
      newPassword === ''
    ) {
      setDirty(false)
    } else {
      setDirty(true)
      setSaved(false)
    }
  }, [lastName, firstName, genderID, birthYear, newPassword, user])

  const getError = (errorName: FieldError) => {
    return (errors.includes(errorName) || undefined) && errorMessages[errorName]
  }

  const onFirstNameChange = (value: string) => {
    const stripped = value.replace(/\d/g, '')
    setFirstName(stripped)
  }

  const onBirthYearChange = (value: string) => {
    const stripped = value.replace(/\D/g, '')
    setBirthYear(stripped === '' ? null : parseInt(stripped, 10))
  }

  const onSubmit = async () => {
    const errorList = validate({ lastName, firstName, birthYear, currentPassword, newPassword })
    if (errorList.length !== 0) {
      setErrors(errorList)
    } else {
      setErrors([])
      try {
        const res = await updateUser({
          lastName,
          firstName,
          birthYear,
          genderID,
          currentPassword,
          newPassword,
        })
        setCurrentPassword('')
        setNewPassword('')
        setUser(res.data.payload)
        setDirty(false)
        setSaved(true)
      } catch {
        setErrors(['currentPasswordInvalid'])
      }
    }
  }

  return (
    <>
      <div className={styles.inputRow}>
        <TextInput value={user.email} label="E-mail cím" disabled className={styles.input} />
        <p className={styles.disclaimer}>
          <Info className={styles.icon} weight="bold" />A regisztráció során megadott e-mail cím nem
          módosítható.
        </p>
      </div>

      <SectionTitle>Személyes adatok</SectionTitle>

      <div className={styles.inputRow}>
        <TextInput
          value={lastName}
          onChange={setLastName}
          label="Vezetéknév"
          className={styles.input}
          errorText={getError('lastNameEmpty')}
        />
        <TextInput
          value={firstName}
          onChange={onFirstNameChange}
          label="Keresztnév"
          className={styles.input}
          errorText={getError('firstNameEmpty')}
        />
      </div>
      <div className={styles.inputRow}>
        <Dropdown
          value={genderID}
          options={genderOptions}
          onChange={(value) => setGenderID(typeof value === 'number' ? value : Genders.other)}
          label="Nem"
          className={styles.input}
        />
        <TextInput
          value={birthYear !== null ? birthYear.toString() : ''}
          onChange={onBirthYearChange}
          label="Születési év (opc.)"
          className={styles.input}
          errorText={getError('birthYearInvalid')}
        />
      </div>

      <SectionTitle>Jelszó megváltoztatása</SectionTitle>

      <div className={styles.inputRow}>
        <PasswordInput
          value={currentPassword}
          onChange={setCurrentPassword}
          label="Jelenlegi jelszó"
          className={styles.input}
          errorText={getError('currentPasswordEmpty') || getError('currentPasswordInvalid')}
        />
        <PasswordInput
          value={newPassword}
          onChange={setNewPassword}
          label="Új jelszó (minimum 8 karakter)"
          className={styles.input}
          errorText={getError('newPasswordTooShort')}
        />
      </div>
      <Button
        variant="primary"
        title={saved ? 'Változások elmentve' : 'Változások mentése'}
        disabled={!dirty}
        onClick={onSubmit}
        className={styles.saveButton}
      />
    </>
  )
}

export default ProfileForm
