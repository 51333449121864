import React, { useState } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Button } from 'components/Button'
import styles from 'pages/ContentPage/Quiz/Quiz.module.scss'
import { GreyOverlay } from 'components/GreyOverlay'
import { Card } from 'components'
import { QuizAnswer, QuizQuestion } from 'services/content.service/content.types'
import { postQuizResults } from 'services/content.service/content.service'
import { useParams } from 'react-router-dom'
import {
  CorrectAnswer,
  IncorrectAnswer,
  NeutralAnswer,
} from 'pages/ContentPage/Quiz/Answers/Answers'
import { Notice } from 'components/Notice'

const isAllQuestionsAnswered = (
  answers: (null | number | undefined)[],
  numOfQuestions: number,
): answers is number[] => {
  return answers.length === numOfQuestions && answers.every((answer) => answer)
}

interface QuizProps {
  quizQuestions: QuizQuestion[]
  close: () => void
}

const Quiz: React.FC<QuizProps> = ({ quizQuestions, close }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<(null | number | undefined)[]>([])
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false)
  const [shouldShowNotice, setShouldShowNotice] = useState(false)
  const contentId = parseInt(useParams()?.contentId ?? '', 10)

  if (!contentId) {
    return null
  }

  const changeAnswer = (questionIndex: number, answerId: number) => {
    const selectedAnswersCopy = selectedAnswers.slice()
    selectedAnswersCopy[questionIndex] = answerId
    setSelectedAnswers(selectedAnswersCopy) // not being able to uncheck is intended
  }

  const finish = () => {
    if (!isAllQuestionsAnswered(selectedAnswers, quizQuestions.length)) {
      return setShouldShowNotice(true)
    }
    setShouldShowNotice(false)
    setIsQuizSubmitted(true)

    return postQuizResults(contentId, selectedAnswers)
  }

  const getAnswerComponent = (questionIndex: number, answer: QuizAnswer) => {
    if (
      selectedAnswers[questionIndex] !== answer.id &&
      quizQuestions[questionIndex].correctAnswerId !== answer.id
    ) {
      return NeutralAnswer
    }

    if (quizQuestions[questionIndex].correctAnswerId === answer.id) {
      return CorrectAnswer
    }

    return IncorrectAnswer
  }

  return (
    <GreyOverlay>
      <Card className={styles.card} header={{ title: 'Teszteld a tudásod', close }}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            {quizQuestions.map((question, index) => (
              <div className={styles.questionContainer} key={index}>
                <h3>
                  <span>&mdash; {index + 1}. kérdés &mdash;</span>
                  <br />
                  {question.title}
                </h3>
                <div className={styles.answerContainer}>
                  {question.answers.map((answer) => {
                    if (isQuizSubmitted) {
                      const Answer = getAnswerComponent(index, answer)
                      return <Answer key={answer.id} answer={answer} />
                    }
                    return (
                      <Checkbox
                        key={answer.id}
                        label={answer.text}
                        value={answer.id === selectedAnswers[index]}
                        toggle={() => changeAnswer(index, answer.id)}
                      />
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.fader} />
        </div>
        {shouldShowNotice && <Notice level="warning" text="Kérlek tölds ki az összes kérdést" />}
        <Button
          onClick={isQuizSubmitted ? close : finish}
          title={isQuizSubmitted ? 'Bezárás' : 'Ellenőrzés'}
          variant={isQuizSubmitted ? 'default' : 'primary'}
          className={styles.button}
        />
      </Card>
    </GreyOverlay>
  )
}

export default Quiz
