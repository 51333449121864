import { Api } from 'services/index'
import {
  AttachmentEdit,
  Content,
  ContentAdmin,
  ContentSearchResult,
  ContentSummary,
  ContentTag,
  CreatableContentTag,
  QuizQuestionEdit,
} from 'services/content.service/content.types'
import { ContentChangableBaseData } from 'pages/Admin/EditContentPage/EditContentBaseData/EditContentBaseData'

export const searchContent = (query: string) => {
  return Api.get<ContentSearchResult>('content/search', { query })
}

export const getContent = (id: number) => {
  return Api.get<Content | ContentAdmin>('content/' + id)
}

export const getRelatedContent = (id: number) => {
  return Api.get<ContentSummary[]>(`content/${id}/related`)
}

export const postQuizResults = (contentId: number, answerIds: number[]) => {
  return Api.post(`content/${contentId}/quiz/results`, answerIds)
}

export const getAvailableTags = () => {
  return Api.get<ContentTag[]>('content/available-tags')
}

export const getAllTags = () => {
  return Api.get<ContentTag[]>('content/all-tags')
}

export const changeContentVideo = (id: number, video: File, onUploadProgress?: (event: ProgressEvent) => void) => {
  return Api.patchFile(`content/${id}/video`, video, onUploadProgress);
}

export const changeContentVideoThumbnail = (id: number, thumbnail: File) => {
  return Api.patchFile(`content/${id}/video/thumbnail`, thumbnail);
}

export const setContentTags = (id: number, tags: CreatableContentTag[]) => {
  return Api.patch(`content/${id}/tags`, tags);
}

export const changeContentTextualData = (id: number, data: ContentChangableBaseData) => {
  return Api.patch(`content/${id}/base-data`, data);
}

export const updateContentQuiz = (id: number, quiz: QuizQuestionEdit[]) => {
  return Api.patch(`content/${id}/quiz`, quiz);
}

export const createTransientContent = () => {
  return Api.post<ContentAdmin>(`content/transient`);
}

export const updateAttachments = (id: number, attachments: AttachmentEdit[]) => {
  return Api.patch(`content/${id}/attachments`, attachments);
}

export const postAttachments = (id: number, formData: FormData) => {
  return Api.postWithFormData(`content/${id}/attachments`, formData);
}
