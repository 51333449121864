import React, { ReactElement } from "react"
import { classNames } from "common/helpers/styling"
import { Check, IconProps } from "phosphor-react"
import { TextArea } from "components/TextInput"
import styles from "./EditableCheckbox.module.scss"

interface EditableCheckboxProps {
  label: string
  setLabel: (value: string) => void
  value: boolean
  toggle?: () => void
  classes?: {
    container?: string
    checkedBox?: string
    inputContainer?: string
  }
  Icon?: (props: IconProps) => ReactElement | null
  disabled?: boolean
  placeholder?: string
}

const EditableCheckbox = ({
  label,
  setLabel,
  value,
  toggle,
  classes,
  Icon = Check,
  disabled,
  placeholder
}: EditableCheckboxProps) => {
  return (
    <div className={classNames(styles.container, classes?.container)}>
      <div
        onClick={() => !disabled && toggle?.()}
        className={classNames(styles.box, disabled && styles.disabled, {
          [classNames(styles.checked, classes?.checkedBox)]: value,
        })}
      >
        <Icon size={26} weight="bold" />
      </div>
      <TextArea
        minRows={1}
        maxRows={2}
        value={label}
        onChange={setLabel}
        disabled={disabled}
        className={classes?.inputContainer}
        placeholder={placeholder}
      />
    </div>
  )
}

export default EditableCheckbox
