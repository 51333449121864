import axios, { AxiosResponse } from 'axios'
import { ApiError } from 'services/apiErrors'

const baseUrl = process.env.REACT_APP_API_BASE_URL

export interface ApiResponse<T = string | object> {
  status: 'ok'
  payload: T
}

export interface ApiErrorPayload {
  status: 'error'
  data: { errorCode: number; message: string }
}

/* eslint-disable */
function transformError() {
  return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor) {
    const fn = descriptor.value
    descriptor.value = async function (...args: any[]) {
      try {
        return await fn.apply(this, args)
      } catch (err) {
        if (ApiError.isErrorApiRelated(err)) {
          throw new ApiError(err)
        }
        throw err
      }
    }
  }
}
/* eslint-enable */

type EdaAxiosResponse<T> = Promise<Required<AxiosResponse<ApiResponse<T>>>>

export class Api {
  @transformError()
  static get<T>(url: string, params?: unknown): EdaAxiosResponse<T> {
    return axios.get(baseUrl + url, {
      withCredentials: true,
      params,
    })
  }

  @transformError()
  static post<T>(url: string, data?: string | object): EdaAxiosResponse<T> {
    return axios.post(baseUrl + url, data, {
      withCredentials: true,
    })
  }

  @transformError()
  static patchFile<T>(url: string, file: File, onUploadProgress?: (event: ProgressEvent) => void): EdaAxiosResponse<T> {
    const data = new FormData();
    data.append('file', file);
    return axios.patch(baseUrl + url, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }

  @transformError()
  static patch<T>(url: string, data: string | object): EdaAxiosResponse<T> {
    return axios.patch(baseUrl + url, data, {
      withCredentials: true,
    })
  }

  @transformError()
  static delete<T>(url: string): EdaAxiosResponse<T> {
    return axios.delete(baseUrl + url, {
      withCredentials: true,
    })
  }

  @transformError()
  static postWithFormData<T>(url: string, data: FormData): EdaAxiosResponse<T> {
    return axios.post(baseUrl + url, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
  }
}
