import { useQueryParams } from 'common/hooks/useQueryParams'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// this is actually not ideal as the setState function's identity changes when the params change
// this can cause performance issues, we could implement something like the new useEvent hook if necessary
export const useQueryParamAsState = <T extends string | null>(paramName: string) => {
  const params = useQueryParams()
  const navigate = useNavigate()
  const state: T = params.get(paramName) as T
  const setState = useCallback(
    (newValue: string | number | null) => {
      if (typeof newValue === 'number') {
        // eslint-disable-next-line no-param-reassign
        newValue = newValue.toString(10)
      }
      if (!newValue) {
        params.delete(paramName)
      } else {
        params.set(paramName, newValue)
      }
      navigate({ search: params.toString() })
    },
    [params, navigate, paramName],
  )

  return [state, setState] as const
}

export const QUERY_PARAMS = {
  search: 'search',
  order: 'order',
  tagIds: 'tag-ids',
  uploadedAfter: 'uploaded-after',
  createdBy: 'created-by',
} as const

export const useSearchQueryParamAsState = () => useQueryParamAsState(QUERY_PARAMS.search)

export type OrderQueryParam = 'date_asc' | 'date_desc' | null
export type UploadedAfterQueryParam = 'last_hour' | 'last_day' | 'last_week' | 'last_month' | null

export const getTagIdArrayFromCSV = (csv: string | null) => {
  if (!csv) {
    return []
  }
  return csv.split(',').map((id) => parseInt(id, 10))
}

export const useOrderQueryParamAsState = () =>
  useQueryParamAsState<OrderQueryParam>(QUERY_PARAMS.order)

export const useTagIdsQueryParamAsState = () => {
  const [tagIds, setTagIds] = useQueryParamAsState(QUERY_PARAMS.tagIds)

  const tagIdsArray = getTagIdArrayFromCSV(tagIds)
  const setTagIdsArray = (ids: number[] | null) =>
    ids === null ? setTagIds(null) : setTagIds(ids.map((id) => id.toString(10)).join(','))

  return [tagIdsArray, setTagIdsArray] as const
}

export const useCreatedByQueryParamAsState = () => useQueryParamAsState(QUERY_PARAMS.createdBy)
