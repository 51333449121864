import React from 'react'
import styles from './Divider.module.scss'

interface DividerProps {
  marginTop?: 'string' | number
  marginBottom?: 'string' | number
}

const Divider: React.FC<DividerProps> = ({ marginBottom, marginTop }) => {
  return <hr className={styles.divider} style={{ marginTop, marginBottom }} />
}

export default Divider
