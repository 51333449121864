import React, { ReactElement } from 'react'
import { classNames } from 'common/helpers/styling'
import { Check, IconProps } from 'phosphor-react'
import styles from './Checkbox.module.scss'

interface CheckboxProps {
  label: string
  value: boolean
  toggle?: () => void
  classes?: {
    container?: string
    checkedBox?: string
  }
  Icon?: (props: IconProps) => ReactElement | null
}

const Checkbox: React.FC<CheckboxProps> = ({ label, value, toggle, classes, Icon = Check }) => {
  return (
    <div className={classNames(styles.container, classes?.container)} onClick={toggle}>
      <div
        className={classNames(styles.box, {
          [classNames(styles.checked, classes?.checkedBox)]: value,
        })}
      >
        <Icon weight="bold" />
      </div>
      <p>{label}</p>
    </div>
  )
}

export default Checkbox
