import React, { useState } from "react"
import { isMatch } from "lodash"
import { changeContentTextualData } from "services/content.service/content.service"
import { ContentAdmin } from "services/content.service/content.types"
import { ChangeSet } from "../EditContentPage"
import DisplayCategoryEditor from "./DisplayCategoryEditor/DisplayCategoryEditor"
import TitleEditor from "./TitleEditor/TitleEditor"
import DescriptionEditor from "./DescriptionEditor/DescriptionEditor"

export type ContentChangableBaseData = Partial<
  Pick<ContentAdmin, "description" | "title" | "userRoleId">
>

interface Props {
  addToChangeSet: (key: keyof ChangeSet, value: () => Promise<unknown>) => void
  removeFromChangeSet: (key: keyof ChangeSet) => void
  currentState: Required<ContentChangableBaseData>
  contentId: number
}

const EditContentBaseData = ({
  addToChangeSet,
  removeFromChangeSet,
  currentState,
  contentId,
}: Props) => {
  const [changeData, setChangeData] = useState<ContentChangableBaseData>(currentState)

  const updateChangeData = (data: ContentChangableBaseData) => {
    const newData = { ...changeData, ...data }
    setChangeData(newData)
    if (isMatch(currentState, data)) {
      removeFromChangeSet("content")
    } else {
      addToChangeSet("content", (targetId?: number) => changeContentTextualData(
        targetId || contentId || 0, newData
      ))
    }
  }

  return (
    <>
      <TitleEditor
        initialValue={currentState.title}
        updateForSave={updateChangeData}
      />
      <DescriptionEditor
        initialValue={currentState.description}
        updateForSave={updateChangeData}
      />
      <DisplayCategoryEditor
        initialValue={currentState.userRoleId}
        updateForSave={updateChangeData}
      />
    </>
  )
}

export default EditContentBaseData
